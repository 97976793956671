import React from 'react';
import { Navigate, Outlet, useLocation,} from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';


const PrivateRoute = () => {
  const { user } = useAuth();
  const location = useLocation()

 
  if (!user) {
    return <Navigate state={{ from: location }} to='/ATLlogin'/>
  }

  return (
    <>
      <Outlet/>
    </>
  );
};

export default PrivateRoute;
