import axios from "axios";

 const axiosWithCredentials = axios.create({
    baseURL: process.env.REACT_APP_BASE_URI, // Adjust the base URL as needed
    withCredentials: true, // Send cookies with the request
    headers: {
      'Content-Type': 'application/json'
    }
  });

  export default axiosWithCredentials