import React, {  useEffect,useRef,useState } from "react";
import "./table.css";
import axios from "../axios";
import toast from 'react-hot-toast'

import { useForm } from "react-hook-form";
function Table() {
  const { register, handleSubmit } = useForm();
  const [selectedOption, setSelectedOption] = useState(1005);
  // const [tableData, setTableData] = useState({});
  const handleDropdownChange = async (e) => {
    setSelectedOption(parseInt(e.target.value, 10)); // Ensure selectedOption is parsed as integer
  };

  const axiosWithCredentials = axios.create({
    baseURL: process.env.REACT_APP_BASE_URI, // Adjust the base URL as needed
    withCredentials: true, // Send cookies with the request
    headers: {
      'Content-Type': 'application/json'
    }
  });

  const updatedData = useRef({});
  const [tableData, setTableData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {

        const response = await axios.post(
          "/getTable",
          { standarCode: selectedOption }
        );
        updatedData.current = response.data.data;
        setTableData(response.data.data); // Update state to trigger re-render

      } catch (error) {
        console.error("Error fetching table data:", error);
      }
    };

    
    fetchData();
  }, [selectedOption]);
  
  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       console.log(typeof selectedOption);
  //       const response = await axios.post(
  //         "/getTable",
  //         { standarCode: selectedOption }
  //       );
  //       setTableData(response.data.data);
  //       updatedDAta.current = response.data.data
        
  //       console.log(response);
  //     } catch (error) {
  //       console.error("Error fetching table data:", error);
  //     }
      
  //   };
  //   console.log("rendring")
  //   fetchData();
  // }, [selectedOption,]);

  async function whileSubmit(data) {
   try {
    const url = "/updateTable"
    data.standarCode=selectedOption

     const responce= await axiosWithCredentials.put(url,data)
     toast.success(responce.data.message)

 
   } catch (error) {
    toast.error(error.response.data.message)
      console.log("Error in axios table")   
   }
  }

  return (
    <main className="table-main gap-x-4">
      <section className="table-Section1 flex flex-col justify-center h-full items-center ">
        <div className="mb-4">
          <select
            className="border-2 rounded-sm "
            value={selectedOption}
            onChange={handleDropdownChange}
          >
            <option value="1005">BND1005</option>
            <option value="1006">BND1006</option>
            <option value="1007">BND1007</option>
            <option value="1008">BND1008</option>
            <option value="1022">BND1022</option>
            <option value="1041">BND1041</option>
            <option value="1042">BND1042</option>
            <option value="100200000">AQCRM100200000</option>
            <option value="100210000">AQCRM100210000</option>
            <option value="100220000">AQCRM100220000</option>
            <option value="100370000">AQCRM100370000</option>
            <option value="100440000">AQCRM100440000</option>
          </select>
        </div>
        <form
          className="justify-center items-center"
          onSubmit={handleSubmit(whileSubmit)}
        >
          <table className="w-full border border-black">
            <thead>
              <tr className=" border border-black">
                <th className="p-1">Temperature (°C)</th>
                <th className="p-1">Δ pH</th>
                <th className="p-1">Temperature (°C)</th>
                <th className="p-1">Δ pH</th>
              </tr>
            </thead>
            <tbody>
              <tr className="border border-black">
                <td className="p-4">5</td>
                <td className="p-4">
                  <input
                    {...register("value11", {
                      required: "Value 11 is required",
                    })}
                    type="text"
                    className="w-full px-2 py-1 border border-black"
                    placeholder="Value 11"
                  />
                </td>
                <td className="p-4">30</td>
                <td className="p-4">
                  <input
                    {...register("value12", {
                      required: "Value 12 is required",
                    })}
                    type="text"
                    className="w-full px-2 py-1 border border-black"
                    placeholder="Value 12"
                  />
                </td>
              </tr>
              <tr className="border border-black">
                <td className="p-4">20</td>
                <td className="p-4">
                  <input
                    {...register("value21", {
                      required: "Value 21 is required",
                    })}
                    type="text"
                    className="w-full px-2 py-1 border border-black"
                    placeholder="Value 21"
                  />
                </td>
                <td className="p-4">35</td>
                <td className="p-4">
                  <input
                    {...register("value22", {
                      required: "Value 22 is required",
                    })}
                    type="text"
                    className="w-full px-2 py-1 border border-black"
                    placeholder="Value 22"
                  />
                </td>
              </tr>
              <tr className="border border-black">
                <td className="p-4">15</td>
                <td className="p-4">
                  <input
                    {...register("value31", {
                      required: "Value 31 is required",
                    })}
                    type="text"
                    className="w-full px-2 py-1 border border-black"
                    placeholder="Value 31"
                  />
                </td>
                <td className="p-4">40</td>
                <td className="p-4">
                  <input
                    {...register("value32", {
                      required: "Value 32 is required",
                    })}
                    type="text"
                    className="w-full px-2 py-1 border border-black"
                    placeholder="Value 32"
                  />
                </td>
              </tr>
              <tr className="border border-black">
                <td className="p-4">20</td>
                <td className="p-4">
                  <input
                    {...register("value41", {
                      required: "Value 41 is required",
                    })}
                    type="text"
                    className="w-full px-2 py-1 border border-black"
                    placeholder="Value 41"
                  />
                </td>
                <td className="p-4">50</td>
                <td className="p-4">
                  <input
                    {...register("value42", {
                      required: "Value 42 is required",
                    })}
                    type="text"
                    className="w-full px-2 py-1 border border-black"
                    placeholder="Value 42"
                  />
                </td>
              </tr>
            </tbody>
          </table>

          <button className="btn-three1" type="submit">Submit</button>
        </form>
      </section>
      <section className="table-Section1 flex flex-col justify-center items-center">
        <table className="w-full table-two border mb-12 border-black">
          <thead className="border border-black">
            <tr className="text-center border border-black">
              <th className="p-4 border border-black">Temperature (°C)</th>
              <th className="p-4 border border-black">Δ pH</th>
              <th className="p-4 border border-black">Temperature (°C)</th>
              <th className="p-4 border border-black">Δ pH</th>
            </tr>
          </thead>
          <tbody>
            <tr className="text-center border border-black">
              <td className="p-4 border border-black">5</td>
              <td className="p-4 border border-black">{tableData?.value11}</td>
              <td className="p-4 border border-black">30</td>
              <td className="p-4 border border-black">{tableData?.value12}</td>
            </tr>
            <tr className="text-center border border-black">
              <td className="p-4 border border-black">20</td>
              <td className="p-4 border border-black">{tableData?.value21}</td>
              <td className="p-4 border border-black">35</td>
              <td className="p-4 border border-black">{tableData?.value22}</td>
            </tr>
            <tr className="text-center border border-black">
              <td className="p-4 border border-black">15</td>
              <td className="p-4 border border-black">{tableData?.value31}</td>
              <td className="p-4 border border-black">40</td>
              <td className="p-4 border border-black">{tableData?.value32}</td>
            </tr>
            <tr className="text-center border border-black">
              <td className="p-4 border border-black">20</td>
              <td className="p-4 border border-black">{tableData?.value41}</td>
              <td className="p-4 border border-black">50</td>
              <td className="p-4 border border-black">{tableData?.value42}</td>
            </tr>
          </tbody>
        </table>
      </section>
    </main>
  );
}

export default Table;
