import React, {useRef, useState} from "react";
import './trial.css'
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { useSelector,  } from "react-redux";
import { useDispatch  } from "react-redux";
import { resetUser } from "../../redux/user.slice.js";
import {Navigate,  useLocation, } from "react-router-dom";


function Trial({preview}) {
  const buttonRef= useRef()
  const [downloaded,setDownloaded]= useState(false)
  const [isSubmitting,setIsSubmitting]= useState(false)
  const formData = useSelector((state) => state.user.user);

  const location = useLocation();



  // console.log(stdData)
  
 
  
  
  
  
  
  const dispatch = useDispatch();
  
  const iframeRef = useRef(null);
  
  const handleGeneratePDF = () => {
    
    if (buttonRef.current) {
      buttonRef.current.disabled = true;
      buttonRef.current.innerHTML = 'LOADING....'
    }
    
  try {
    if(true){

    const iframeDocument = iframeRef.current.contentWindow.document;
    const receiptWrap1 = iframeDocument?.querySelector("#body1");
    const receiptWrap2 = iframeDocument?.querySelector("#body2");

    // console.log(`This is wraper 1 ${ receiptWrap1} and this is wraper 2 ${ receiptWrap2}`)
    if (receiptWrap1 && receiptWrap2) {

      
      if(preview){
       const style= iframeDocument.createElement('style');
        style.innerHTML= `
        #expiry{
          color: red;
        }
        #value11{
          color: red;
        }
        #value12{
          color: red;
        }
        #value21{
          color: red;
        }
        #value22{
          color: red;
        }
        #value31{
          color: red;
        }
        #value32{
          color: red;
        }
        #value41{
          color: red;
        }
        #value42{
          color: red;
        }
        #authorizedSignatory{
          color: red;
        }
        #issuingAuthority{
          color: red;
        }
        #expandedUncertainty{
          color: red;
        }
        #expandedUncertainty1{
          color: red;
        }
        #certifiedValue{
          color: red;
        }
        #certifiedValue1{
          color: red;
        }
        #certificateIssueDate{
          color: red;
        }
        #documentVersion{
          color: red;
        }
        #volume{
          color: red;
        }
        .lotNumber{
          color: red;
        }
        `
        iframeDocument.head.appendChild(style);
      }
      
      
      const currentDate = new Date(formData.createdAt);

      // Format the current date and time using toLocaleString()
      console.log(2)
      
      
      const currentDateTimeFormatted = currentDate.toLocaleString();
      const credentials = iframeDocument.getElementsByClassName("receipt-header");
      credentials[0].innerHTML = ` ${formData.companyName} - ${formData.stdData}   `;
      credentials[1].innerHTML = ` ${formData.companyName} - ${formData.stdData}  `;
      const createdDate = iframeDocument.getElementsByClassName("created-date");
      createdDate[0].innerHTML = `  ${currentDateTimeFormatted} `;
      createdDate[1].innerHTML = `  ${currentDateTimeFormatted} `;
      
      if(formData.table){

        const value11 = iframeDocument.querySelector("#value11");
        value11.innerHTML = `${formData.table.value11 || ""}`;
        const value12 = iframeDocument.querySelector("#value12");
        value12.innerHTML = `${formData.table.value12 || ""}`;
        const value21 = iframeDocument.querySelector("#value21");
        value21.innerHTML = `${formData.table.value21 || ""}`;
        const value22 = iframeDocument.querySelector("#value22");
        value22.innerHTML = `${formData.table.value22 || ""}`;
        const value31 = iframeDocument.querySelector("#value31");
        value31.innerHTML = `${formData.table.value31 || ""}`;
        const value32 = iframeDocument.querySelector("#value32");
        value32.innerHTML = `${formData.table.value32 || ""}`;
        const value41 = iframeDocument.querySelector("#value41"); 
        value41.innerHTML = `${formData.table.value41 || ""}`;
        const value42 = iframeDocument.querySelector("#value42");
        value42.innerHTML = `${formData.table.value42 || ""}`;
      }
        
      const expiry = iframeDocument.getElementById("expiry");
if (expiry) {
    expiry.innerHTML = `${formData.fullStdData.expiry || ""}`;
} else {
    console.error("Element with ID 'expiry' not found.");
}
       
       const authorizedSignatory = iframeDocument.querySelector("#authorizedSignatory");
       authorizedSignatory.innerHTML = `${formData.fullStdData.authorizedSignatory || ""}`;
       
       const issuingAuthority = iframeDocument.querySelector("#issuingAuthority");
       issuingAuthority.innerHTML = `${formData.fullStdData.issuingAuthority || ""}`;
       
       const expandedUncertainty = iframeDocument.querySelector("#expandedUncertainty");
       expandedUncertainty.innerHTML = `${formData.fullStdData.expandedUncertainty || ""}`;
       
       const certifiedValue = iframeDocument.querySelector("#certifiedValue");
       certifiedValue.innerHTML = `${formData.fullStdData.certifiedValue || ""}`;
       console.log(2)
       
      
       const expandedUncertainty1 = iframeDocument.querySelector("#expandedUncertainty1");
       expandedUncertainty1!==null?expandedUncertainty1.innerHTML = `${formData.fullStdData.expandedUncertainty1 || ""}`:console.log();
       
       const certifiedValue1 = iframeDocument.querySelector("#certifiedValue1");
       certifiedValue1!== null? certifiedValue1.innerHTML = `${formData.fullStdData.certifiedValue1 || ""}`:console.log();
       
       const standardCode = iframeDocument.getElementsByClassName("standard-code");
       for (let i = 0; i < standardCode.length; i++) {
        standardCode[i].innerHTML = `${formData.fullStdData.standarCode || ""}`;
      }
       
      const certificateIssueDate = iframeDocument.querySelector("#certificateIssueDate");
      certificateIssueDate.innerHTML = ` ${formData.fullStdData.certificateIssueDate === undefined?console.log() :formData.fullStdData.certificateIssueDate }`;
      
      const documentVersion = iframeDocument.querySelector("#documentVersion");
      documentVersion.innerHTML = `${formData.fullStdData.documentVersion || ""}`;
      
      const volume = iframeDocument.querySelector("#volume");
      volume.innerHTML = `${formData.fullStdData.volume || ""}`;
      
      const validation = iframeDocument?.querySelector(".validation");
      !validation ? console.log(): validation.innerHTML = `${formData?.fullStdData.validation || ""}`;
      

      const lotNumber = iframeDocument.getElementsByClassName("lotNumber");
      // console.log('This is my lot number ',lotNumber)
      for (let i = 0; i < lotNumber.length; i++) {
        lotNumber[i].innerHTML = `${formData.fullStdData.lotNumber || ""}`;
      }

      
      console.log(2)
      
      console.log(3)
      html2canvas(receiptWrap1, {
        scrollY: -window.scrollY,
        scale: 2,
        dpi: 600,
        useCORS: true, // Enable cross-origin images
        logging: true, // Enable logging
        onclone: (document) => {
          // Manipulate the cloned document if needed
          // const clonedReceiptWrap1 = document.querySelector("#receiptWrap1");
          // Ensure all absolutely positioned elements are visible
        },
      }).then((canvas1) => {
        const imgData1 = canvas1.toDataURL("image/png");
        const imgWidth = 210; // A4 width in mm
        const imgHeight1 = (canvas1.height * imgWidth) / canvas1.width;
        
        console.log(4)
        const pdf = new jsPDF({
          orientation: "portrait", // Use 'landscape' for landscape orientation
          unit: "mm",
          format: "a4",
        });
        console.log(5)
        
        pdf.addImage(imgData1, "PNG", 0, 0, imgWidth, imgHeight1);
        
        console.log(6)
        html2canvas(receiptWrap2, {
        scrollY: -window.scrollY,
        scale: 2,
        dpi: 600,
        useCORS: true, // Enable cross-origin images
        logging: true, // Enable logging
        onclone: (document) => {
          // Manipulate the cloned document if needed
          // const clonedReceiptWrap1 = document.querySelector("#receiptWrap2");
          // Ensure all absolutely positioned elements are visible
        },
        }).then((canvas2) => {
          const imgData2 = canvas2.toDataURL("image/png");
          const imgHeight2 = (canvas2.height * imgWidth) / canvas2.width;
          
          pdf.addPage();
          pdf.addImage(imgData2, "PNG", 0, 0, imgWidth, imgHeight2);
          
          
          pdf.save(formData.fullStdData.standarCode>2000? `AQCRM${formData.fullStdData.standarCode}.pdf`:`BND${formData.fullStdData.standarCode}.pdf` );
          dispatch(resetUser());
          setDownloaded(true)
          
        });
      });
      console.log(7)
    } else {
      const iframeDocument = iframeRef.current.contentWindow.document;
      const receiptWrap = iframeDocument?.querySelector("#body");
      const currentDate = new Date(formData.createdAt);
      console.log(1)
      // Format the current date and time using toLocaleString()
      const currentDateTimeFormatted = currentDate.toLocaleString();
      const credentials = iframeDocument.getElementsByClassName("receipt-header");
      credentials[0].innerHTML = ` ${formData.companyName}   `;
      console.log(2)
      
      const createdDate = iframeDocument.getElementsByClassName("created-date");
      createdDate[0].innerHTML = `  ${currentDateTimeFormatted} `;
      
      const authorizedSignatory = iframeDocument.querySelector("#authorizedSignatory");
      authorizedSignatory.innerHTML = `${formData.fullStdData.authorizedSignatory || ""}`;
      console.log(3)
      
      const issuingAuthority = iframeDocument.querySelector("#issuingAuthority");
      issuingAuthority.innerHTML = `${formData.fullStdData.issuingAuthority || ""}`;
      
      if(Number(formData.fullStdData.standarCode) < 200000000  ){

        const expandedUncertainty = iframeDocument.querySelector("#expandedUncertainty");
        expandedUncertainty.innerHTML = `${formData.fullStdData.expandedUncertainty || ""}`;
        
        const certifiedValue = iframeDocument.querySelector("#certifiedValue");
        certifiedValue.innerHTML = `${formData.fullStdData.certifiedValue || ""}`;
        console.log(2)
      }
      const certificateIssueDate = iframeDocument.querySelector("#certificateIssueDate");
      certificateIssueDate.innerHTML = `${formData.fullStdData.certificateIssueDate || ""}`;
      
      const documentVersion = iframeDocument.querySelector("#documentVersion");
      documentVersion.innerHTML = `${formData.fullStdData.documentVersion || ""}`;
      console.log(4)
      
      const standardCode = iframeDocument.getElementsByClassName("standard-code");

      for (let i = 0; i < standardCode.length; i++) {
        standardCode[i].innerHTML = `${formData.fullStdData.standarCode || ""}`;
     }
      
      const volumeElements = iframeDocument.getElementsByClassName("volume");
      for (let i = 0; i < volumeElements.length; i++) {
        volumeElements[i].innerHTML = `${formData.fullStdData.volume || ""}`;
      }
      
      const expiry = iframeDocument.getElementById("expiry");
if (expiry) {
    expiry.innerHTML = `${formData.fullStdData.expiry || ""}`;
} else {
    console.error("Element with ID 'expiry' not found.");
}
      
      
      const lotNumber = iframeDocument.getElementsByClassName("lotNumber");

      for (let i = 0; i < lotNumber.length; i++) {
        lotNumber[i].innerHTML = `${formData.fullStdData.lotNumber || ""}`;
      }

      

      html2canvas(receiptWrap, {
        scrollY: -window.scrollY,
        scale: 2,
        dpi: 600,
        useCORS: true, // Enable cross-origin images
        logging: true, // Enable logging
        onclone: (document) => {
          // Manipulate the cloned document if needed
          // const clonedReceiptWrap1 = document.querySelector("#receiptWrap1");
          // Ensure all absolutely positioned elements are visible
        },
      }).then((canvas1) => {
        const imgData1 = canvas1.toDataURL("image/png");
        const imgWidth = 210; // A4 width in mm
        const imgHeight1 = (canvas1.height * imgWidth) / canvas1.width;
        
        console.log(4)
        const pdf = new jsPDF({
          orientation: "portrait", // Use 'landscape' for landscape orientation
          unit: "mm",
          format: "a4",
        });
        console.log(5)
        
        pdf.addImage(imgData1, "PNG", 0, 0, imgWidth, imgHeight1);
        
        
          
        pdf.save(formData.fullStdData.standarCode>2000? `AQCRM${formData.fullStdData.standarCode}.pdf`:`BND${formData.fullStdData.standarCode}.pdf` );
          dispatch(resetUser());
          setDownloaded(true)
          
      
      });
    }
    
    } else {
      console.error("Element with id 'receipt-wrap' not found in the iframe");
    }
    
  
  
  } catch (error) {
    
   
    console.log("error"+error)
  
  }
  setIsSubmitting(false)
}
  
  return (
    <div className="container h-full mx-auto p-4 flex flex-col  items-center">
      <div className="w">

      <h2 className={`${preview?'sr-only':'text-2xl font-bold mb-4'}`}>
      Kindly ensure that your downloaded certificate bears your lab name, location, date, and time.{" "}
      </h2>
      <h2 className={`${!preview?'sr-only':'text-2xl font-bold mb-4'}`}>
      Kindly Download the certificate to see the updated values.
      </h2>

      <button
        id="btn-two"
        onClick={handleGeneratePDF}
        ref={buttonRef}
        className={`bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600 focus:outline-none focus:bg-green-600 ${isSubmitting?'':''}`}
        >
        {isSubmitting===true?<div className="loader"></div> : 'Download certificate'}
      </button>
      <iframe
        ref={iframeRef}
        // src={`/${formData.fullStdData.standarCode}.html`}
        // src={`/${formData.fullStdData.standarCode > 3000? formData.fullStdData.standarCode - formData.fullStdData.volume :formData.fullStdData.standarCode}.html`}
        src={`https://aashvitechnology.in/${formData.fullStdData.standarCode > 3000? formData.fullStdData.standarCode - formData.fullStdData.volume :formData.fullStdData.standarCode}.html`}
        title="Receipt Iframe"
        className={`${preview ? 'sr-only':'sr-only'}`}
        style={{
          height: "550px",
          width: "850px",
          backgroundColor: "transparent",
        }}
        ></iframe>
     {downloaded===true && !preview? <Navigate to="/" state={{from:location}} replace />:console.log()}
        </div>
    </div>
  );
}

export default Trial;



// import React, {useRef, useState} from "react";
// import { arr } from "./temp";
// import html2canvas from "html2canvas";
// import jsPDF from "jspdf";
// // import { useSelector,  } from "react-redux";
// // import { useDispatch  } from "react-redux";
// // import { resetUser } from "../../redux/user.slice.js";
// import {Navigate,  useLocation, } from "react-router-dom";


// function Trial() {
//   const [downloaded,setDownloaded]= useState(false)
//   // const formData = useSelector((state) => state.user.user);

//   const location = useLocation();



 
 
 
//   const formData=arr[23]
//   console.log(formData)
 
//   // console.log("formdata", formData)
 
//   // const dispatch = useDispatch();
  
//   const iframeRef = useRef(null);
  
//   const handleGeneratePDF = () => {
   
//         try {
//           if(formData.fullStdData.standarCode<2000){
//             console.log(1)
//           const iframeDocument = iframeRef.current.contentWindow.document;
//           const receiptWrap1 = iframeDocument.querySelector("#body1");
//           const receiptWrap2 = iframeDocument.querySelector("#body2");
//           console.log(`This is wraper 1 ${ receiptWrap1} and this is wraper 2 ${ receiptWrap2}`)
//           if (receiptWrap1 && receiptWrap2) {
            
            
            
//             const currentDate = new Date(formData.createdAt);
//             console.log(formData.createdAt)
//             // Format the current date and time using toLocaleString()
//             console.log(2)
//             const currentDateTimeFormatted = currentDate.toLocaleString();
//             const credentials = iframeDocument.querySelector(".receipt-header");
//             credentials.innerHTML = `Company Name = ${formData.companyName} -   ${currentDateTimeFormatted} `;
            
            
//             // switch (formData.fullStdData.standarCode) {
//             //   case 1005:
//             // case 1006:
//             // case 1007:
//             // case 1008:
//             // case 1022:
//             // case 1041:
//             // case 1042:
//             //   console.log(formData)
//             //   const value11 = iframeDocument.querySelector("#value11");
//             //   value11.innerHTML = `${formData.table.value11 || ""}`;
//             //   const value12 = iframeDocument.querySelector("#value12");
//             //   value12.innerHTML = `${formData.table.value12 || ""}`;
//             //   const value21 = iframeDocument.querySelector("#value21");
//             //   value21.innerHTML = `${formData.table.value21 || ""}`;
//             //   const value22 = iframeDocument.querySelector("#value22");
//             //   value22.innerHTML = `${formData.table.value22 || ""}`;
//             //   const value31 = iframeDocument.querySelector("#value31");
//             //   value31.innerHTML = `${formData.table.value31 || ""}`;
//             //   const value32 = iframeDocument.querySelector("#value32");
//             //   value32.innerHTML = `${formData.table.value32 || ""}`;
//             //   const value41 = iframeDocument.querySelector("#value41"); 
//             //   value41.innerHTML = `${formData.table.value41 || ""}`;
//             //   const value42 = iframeDocument.querySelector("#value42");
//             //   value42.innerHTML = `${formData.table.value42 || ""}`;
              
//             //   console.log(2.1)
//             //   break;
              
//             //   default:
//             //     break;
//             //   }
              
//               const expiry = iframeDocument.querySelector("#expiry");
//              expiry.innerHTML = `${formData.fullStdData.expiry || ""}`;
             
//              const authorizedSignatory = iframeDocument.querySelector("#authorizedSignatory");
//              authorizedSignatory.innerHTML = `${formData.fullStdData.authorizedSignatory || ""}`;
             
//              const issuingAuthority = iframeDocument.querySelector("#issuingAuthority");
//              issuingAuthority.innerHTML = `${formData.fullStdData.issuingAuthority || ""}`;
             
//              const expandedUncertainty = iframeDocument.querySelector("#expandedUncertainty");
//              expandedUncertainty.innerHTML = `${formData.fullStdData.expandedUncertainty || ""}`;
             
//              const certifiedValue = iframeDocument.querySelector("#certifiedValue");
//              certifiedValue.innerHTML = `${formData.fullStdData.certifiedValue || ""}`;
             
//              console.log(3)
//              const expandedUncertainty1 = iframeDocument.querySelector("#expandedUncertainty1");
//              expandedUncertainty1!==null?expandedUncertainty1.innerHTML = `${formData.fullStdData.expandedUncertainty1 || ""}`:console.log();
             
//              const certifiedValue1 = iframeDocument.querySelector("#certifiedValue1");
//              certifiedValue1!== null? certifiedValue1.innerHTML = `${formData.fullStdData.certifiedValue1 || ""}`:console.log();
//              console.log(4)
             
//             const certificateIssueDate = iframeDocument.querySelector("#certificateIssueDate");
//             certificateIssueDate.innerHTML = ` ${formData.fullStdData.certificateIssueDate === undefined?console.log() :formData.fullStdData.certificateIssueDate }`;
            
//             const documentVersion = iframeDocument.querySelector("#documentVersion");
//             documentVersion.innerHTML = `${formData.fullStdData.documentVersion || ""}`;
            
//             const volume = iframeDocument.querySelector("#volume");
//             volume.innerHTML = `${formData.fullStdData.volume || ""}`;
            
      
//             const lotNumber = iframeDocument.querySelector(".lotNumber");
//             lotNumber.innerHTML = `LOT NO. ${formData.fullStdData.lotNumber || ""}`;
      
            
//             console.log(2)
            
//             console.log(3)
//             html2canvas(receiptWrap1, {
//               scrollY: -window.scrollY,
//               scale: 2,
//               dpi: 600,
//             }).then((canvas1) => {
//               const imgData1 = canvas1.toDataURL("image/png");
//               const imgWidth = 210; // A4 width in mm
//               const imgHeight1 = (canvas1.height * imgWidth) / canvas1.width;
              
//               console.log(4)
//               const pdf = new jsPDF({
//                 orientation: "portrait", // Use 'landscape' for landscape orientation
//                 unit: "mm",
//                 format: "a4",
//               });
//               console.log(5)
              
//               pdf.addImage(imgData1, "PNG", 0, 0, imgWidth, imgHeight1);
              
//               console.log(6)
//               html2canvas(receiptWrap2, {
//                 scrollY: -window.scrollY,
//                 scale: 2,
//                 dpi: 600,
//               }).then((canvas2) => {
//                 const imgData2 = canvas2.toDataURL("image/png");
//                 const imgHeight2 = (canvas2.height * imgWidth) / canvas2.width;
                
//                 pdf.addPage();
//                 pdf.addImage(imgData2, "PNG", 0, 0, imgWidth, imgHeight2);
                
//                 pdf.save("BND${formData.fullStdData.standarCode}.pdf");
//                 // dispatch(resetUser());
//                 setDownloaded(true)
                
//               });
//             });
//             console.log(7)
//           } else {
//             const iframeDocument = iframeRef.current.contentWindow.document;
//           const receiptWrap = iframeDocument.querySelector("#body");
//           if (receiptWrap) {
//             html2canvas(receiptWrap, {
//               scrollY: -window.scrollY,
//               scale: 2,
//               dpi: 600,
//               useCORS: true, // Enable cross-origin images
//               logging: true, // Enable logging
//               onclone: (document) => {
//                 // Manipulate the cloned document if needed
//                 const clonedReceiptWrap1 = document.querySelector("#receiptWrap1");
//                 // Ensure all absolutely positioned elements are visible
//               },
//             }).then((canvas1) => {
//               const imgData1 = canvas1.toDataURL("image/png");
//               const imgWidth = 210; // A4 width in mm
//               const imgHeight1 = (canvas1.height * imgWidth) / canvas1.width;
              
//               console.log(4)
//               const pdf = new jsPDF({
//                 orientation: "portrait", // Use 'landscape' for landscape orientation
//                 unit: "mm",
//                 format: "a4",
//               });
//               console.log(5)
              
//               pdf.addImage(imgData1, "PNG", 0, 0, imgWidth, imgHeight1);
              
              
                
//                 pdf.save("RefrenceMaterial.pdf");
//                 // dispatch(resetUser());
//                 setDownloaded(true) });}
//             console.error("Elements with id 'body1' or 'body2' not found in the iframe");
//           }
//           }else{
//             console.log('a')
//             const iframeDocument = iframeRef.current.contentWindow.document;
//             const receiptWrap = iframeDocument.querySelector("#body");
//             console.log('b')
            
            
//             if (receiptWrap) {
              
//               const credentials = iframeDocument.querySelector(".receipt-header");
//               const currentDate = new Date(formData.createdAt);
              
//               // Format the current date and time using toLocaleString()
//               const currentDateTimeFormatted = currentDate.toLocaleString();
              
//               credentials.innerHTML = `${formData.companyName} /n ${currentDateTimeFormatted} `;
              
              
              
//               const authorizedSignatory = iframeDocument.querySelector("#authorizedSignatory");
//               authorizedSignatory.innerHTML = `${formData.fullStdData.authorizedSignatory || ""}`;
              
//               const issuingAuthority = iframeDocument.querySelector("#issuingAuthority");
//               issuingAuthority.innerHTML = `${formData.fullStdData.issuingAuthority || ""}`;
              
//               if(Number(formData.fullStdData.standarCode)===101000040 || Number(formData.fullStdData.standarCode)===101000041 ){
//                 const expandedUncertainty = iframeDocument.querySelector("#expandedUncertainty");
//                 expandedUncertainty.innerHTML = `± ${formData.fullStdData.expandedUncertainty || ""}`;
//                 console.log('c')
                
//                 const certifiedValue = iframeDocument.querySelector("#certifiedValue");
//                 certifiedValue.innerHTML = `${formData.fullStdData.certifiedValue || ""}`;
//               }
//               const certificateIssueDate = iframeDocument.querySelector("#certificateIssueDate");
//               certificateIssueDate.innerHTML = ` ${formData.fullStdData.certificateIssueDate || ""}`;
              
//               const documentVersion = iframeDocument.querySelector("#documentVersion");
//               documentVersion.innerHTML = `${formData.fullStdData.documentVersion || ""}`;
              
              
//               const volumeElements = iframeDocument.getElementsByClassName("volume");
//               for (let i = 0; i < volumeElements.length; i++) {
//                 volumeElements[i].innerHTML = `${formData.fullStdData.volume || ""}`;
//               }
              
//               const expiry = iframeDocument.getElementsByClassName("expiry");
//               for (let i = 0; i < expiry.length; i++) {
//                 expiry[i].innerHTML = `${formData.fullStdData.expiry || ""}`;
//               }
              
              
//               const lotNumber = iframeDocument.getElementsByClassName("lotNumber");
//               for (let i = 0; i < lotNumber.length; i++) {
//                 lotNumber[i].innerHTML = `${formData.fullStdData.lotNumber || ""}`;
//               }
              
              
              
//               html2canvas(receiptWrap, {
//                 scrollY: -window.scrollY,
//                 scale: 2,
//                 dpi: 600,
//               }).then((canvas1) => {
//                 const imgData1 = canvas1.toDataURL("image/png");
//                 const imgWidth = 210; // A4 width in mm
//                 const imgHeight1 = (canvas1.height * imgWidth) / canvas1.width;
                
//                 console.log(4)
//                 const pdf = new jsPDF({
//                   orientation: "portrait", // Use 'landscape' for landscape orientation
//                   unit: "mm",
//                   format: "a4",
//                 });
//               console.log(5)
              
//               pdf.addImage(imgData1, "PNG", 0, 0, imgWidth, imgHeight1);
              
              
                
//                 pdf.save(`BND.pdf`);
//                 // dispatch(resetUser());
//                 setDownloaded(true)
                
            
//             });
//           } else {
//             console.error("Element with id 'receipt-wrap' not found in the iframe");
//           }
//           }
          
        
//         } catch (error) {
          
          
//           console.log("error")
        
//         }
        
      
     
   
  
// }
  
//   return (
//     <div className="container mx-auto p-4">
//       <h2 className="text-2xl font-bold mb-4">
//         We ensure that every certificate downloaded bears your company name,
//         email address, and GST number for proper identification and compliance
//         purposes.{" "}
//       </h2>
//       <iframe
//         ref={iframeRef}
//         src={`/${Number(formData.fullStdData.standarCode) - Number(formData.fullStdData.volume)}.html`}
//         title="Receipt Iframe"
//         className="sr-only"
//         style={{
//           height: "250px",
//           width: "450px",
//           backgroundColor: "transparent",
//         }}
//       ></iframe>

//       <button
//         id="btn-two"
//         onClick={handleGeneratePDF}
//         className="bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600 focus:outline-none focus:bg-green-600"
//       >
//         Generate PDF
//       </button>
        
//     </div>
//   );
// }

// export default Trial;
