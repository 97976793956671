import React from "react";

function application() {
  return (
    <div className="flex flex-col justify-center items-center space-y-4">
      <h1 className="text-3xl font-bold mt-2">Application</h1>
      <div className="bg-neutral-500 w-1/2 h-4/5 gap-4 m-3 rounded-md opacity-70">
        <div className="flex m-3 space-x-2">
          <input
            type="radio"
            id="option1"
            name="option"
            className="form-radio text-indigo-600 h-5 w-5"
          />
          <label htmlFor="option1" className="text-white ">
            Unable to download Certificate
          </label>
        </div>

        <div className="flex items-center m-3 space-x-2">
          <input
            type="radio"
            id="option2"
            name="option"
            className="form-radio text-indigo-600 h-5 w-5"
          />
          <label htmlFor="option2" className="text-white">
            Entered Wrong Details
          </label>
        </div>

        <div className="flex items-center m-3 space-x-2">
          <input
            type="radio"
            id="option3"
            name="option"
            className="form-radio text-indigo-600 h-5 w-5"
          />
          <label htmlFor="option3" className="text-white">
            Other
          </label>
        </div>

        <div className=" ">
          <textarea
            type="text"
            name="option"
            id="problem"
            placeholder="State your Problem"
            className="border w-1/2 border-gray-300 bg-gray-300 rounded-md px-3 py-2 focus:outline-none focus:border-indigo-500 flex-grow"
          />
        </div>

        <div className="flex items-center">
          <button
            type="submit"
            className="bg-indigo-500 text-white px-4 py-2 m-3 rounded-md hover:bg-indigo-600 focus:outline-none focus:bg-indigo-600"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
}

export default application;
