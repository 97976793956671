import React,{ useState} from 'react'
import './form.css'
import { useForm,} from 'react-hook-form'
import { Navigate, useLocation } from 'react-router-dom'
import axios from '../axios.public.js'
import { useDispatch } from 'react-redux'
import { setUser } from '../../redux/user.slice.js'


 function Login() {
 
 const [isDownloaded,setIsDownloaded]= useState(false)
 
 const [message,setMessage] = useState('')
  const location = useLocation()
  
const {register,handleSubmit , formState: { errors,isSubmitting }} = useForm()
const dispatch = useDispatch()

async function onsubmit(data){
  try {

    const responce = await axios.post('/userData', data)
    console.log(responce)
    if(!responce.data.data){
      return setMessage(responce.data.message)
    }
    if (responce.data.status === true){
    
      setIsDownloaded(true)
  console.log(responce.data.data)
      dispatch(setUser(responce.data.data));
    }
  
  } catch (error) {
    console.log(error)
  }
}
   
  
      // dispatch(setStdName(respData.product.stdName));
      // dispatch(setLotNumber(respData.product.lotNumber));
      // dispatch(setExpiry(respData.product.expiry));
      // dispatch(setEun(respData.product.eun));
      
    
   




return (
  <div className='nav grid grid-cols-2 justify-items-center items-center h-screen  '>
      <form id='form' className='flex flex-col align-middle w-96 items-center h-4/5' onSubmit={handleSubmit(onsubmit)} >
        <h2 className='text-2xl font-semibold text-black mt-4'>Product Details</h2>
        
       
        <input {...register("_Id",{
          required:"Bottle Number is required ",
          minLength:{
            value:24,
            message:"Bottle number must contain 24 charactors"
          },
          maxLength:{
            value:24,
            message:"Bottle number must contain 24 charactors"
          }
        })} type="text" aria-autocomplete='none' className='input p-5 w-1/2 border-2 border-black  m-1' placeholder='Bottle Code' />
          {errors._Id && <div className='text-lg text-red-600'>{errors._Id.message}</div>}
        <input {...register("lotNumber",
        {
          required:"PLease Write Standard code "
        }
      )} type="text" aria-autocomplete='none' className='input p-5 w-1/2 border-2 border-black m-1' placeholder='Lot Number' />
      {errors.lotNumber && <div className='text-lg text-red-600'>{errors.lotNumber.message}</div>}
        <input {...register("standarCode",{
          required:"Please Write BND/REfrence Number "
        })} type="number" aria-autocomplete='none' className='input p-5 w-1/2 border-2 border-black m-1' placeholder='BND/CRM/RM- Number' />
          {errors.standarCode && <div className='text-lg text-red-600'>{errors.standarCode.message}</div>}
         <input {...register("companyName",{
           required:"Please enter your Lab Name"
          })} type="text"
          placeholder='Lab Name'
          className='input p-5 w-1/2 border-2 border-black m-1' />
          {errors.companyName && <div className='text-lg text-red-600'>{errors.companyName.message}</div>}
         <input {...register("email",{
           required:"Please enter your Email"
          })} type="text"
          placeholder='Lab Email'
          className='input p-5 w-1/2 border-2 border-black m-1' />
        {errors.email && <div className='text-lg text-red-600'>{errors.email.message}</div>}
         <input {...register("gstNumber",{
           required:"Please enter your GST Number"
          })} type="text"
          placeholder='GST Number'
          className='input p-5 w-1/2 border-2 border-black m-1' />
          {errors.gstNumber && <div className='text-lg text-red-600'>{errors.gstNumber.message}</div>}
        {<p className='text-red-500'>{message}</p>}
        <button 
      type="submit" 
      disabled={isSubmitting} 
      className={`mt-4 border border-blue-600 p-3 w-28 rounded-full bg-blue-500 text-white font-semibold hover:bg-white hover:text-blue-500 transition ${isSubmitting ? 'loading' : 'btn-sub'}`}
    >
      {isSubmitting ? <div className="loader"></div> : 'Submit'}
    </button>
     
      </form>
      {isDownloaded === true ? <Navigate to={`/download`} state={{from:location}} replace={true} /> : null}


    </div>
  )
}

export default Login
