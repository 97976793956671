import React, { useEffect, useState } from "react";
import { useSwitchCase } from "../../../hooks/useSwitchCase";
import "./addStandard.css";
import { TypeOne, TypeTwo, TypeThree } from "./Forms";

const AddStandard = () => {

  let aqcrm = [
    { standardCode: "100010000", standardName: "Barium Standard Solution" },
    { standardCode: "100020000", standardName: "Copper Standard Solution" },
    { standardCode: "100030000", standardName: "Iron Standard Solution" },
    { standardCode: "100030000", standardName: "Iron Standard Solution" },
    { standardCode: "100040000", standardName: "Selenium Standard Solution" },
    { standardCode: "100050000", standardName: "Aluminum Standard Solution" },
    { standardCode: "100060000", standardName: "Lead Standard Solution" },
    { standardCode: "100070000", standardName: "Arsenic Standard Solution" },
    { standardCode: "100080000", standardName: "Mercury Standard Solution" },
    { standardCode: "100090000", standardName: "Cadmium Standard Solution" },
    { standardCode: "100100000", standardName: "Silver Standard Solution" },
    { standardCode: "100110000", standardName: "Manganese Standard Solution" },
    { standardCode: "100120000", standardName: "Sodium Standard Solution" },
    { standardCode: "100130000", standardName: "Potassium Standard Solution" },
    { standardCode: "100140000", standardName: "Zinc Standard Solution" },
    { standardCode: "100150000", standardName: "Chromium Standard Solution" },
    { standardCode: "100160000", standardName: "Nickel Standard Solution" },
    { standardCode: "100170000", standardName: "Antimony Standard Solution" },
    { standardCode: "100180000", standardName: "Bromate Standard Solution" },
    { standardCode: "100190000", standardName: "Boron Standard Solution" },
    { standardCode: "100200000", standardName: "pH Buffer Solution 4.00" },
    { standardCode: "100210000", standardName: "pH Buffer Solution 7.00" },
    { standardCode: "100220000", standardName: "pH Buffer Solution 9.00" },
    { standardCode: "100230000", standardName: "TDS Solution" },
    { standardCode: "100240000", standardName: "Nitrate Standard Solution" },
    { standardCode: "100250000", standardName: "Nitrite Standard Solution" },
    { standardCode: "100260000", standardName: "Sulphate Standard Solution" },
    { standardCode: "100270000", standardName: "Fluoride Standard Solution" },
    { standardCode: "100280000", standardName: "Calcium Standard Solution" },
    { standardCode: "100290000", standardName: "Magnesium Standard Solution" },
    { standardCode: "100300000", standardName: "Conductivity Standard Solution 1.41" },
    { standardCode: "100310000", standardName: "Potassium Hydrogen Phthalate" },
    { standardCode: "100320000", standardName: "Sodium Chloride" },
    { standardCode: "100330000", standardName: "Potassium Dichromate" },
    { standardCode: "100340000", standardName: "Calcium Carbonate" },
    { standardCode: "100350000", standardName: "Sodium Carbonate" },
    { standardCode: "100360000", standardName: "Ammonium Sulphate (Purity)" },
    { standardCode: "100370000", standardName: "pH Buffer Solution 9.20" },
    { standardCode: "100380000", standardName: "Conductivity Standard Solution 0.147" },
    { standardCode: "100390000", standardName: "Conductivity Standard Solution 12.8" },
    { standardCode: "100400000", standardName: "Colour Standard Solution" },
    { standardCode: "100410000", standardName: "Turbidity Standard Solution" },
    { standardCode: "100420000", standardName: "Potassium Chloride" },
    { standardCode: "100430000", standardName: "Ammonium Sulphate (N2)" },
    { standardCode: "100440000", standardName: "pH Buffer Solution 10.00" },
  ]

  let oil = [ 
    { standardCode: "200010000", standardName: "Groundnut Oil" },
    { standardCode: "200020000", standardName: "Sesame Oil" },
    { standardCode: "200030000", standardName: "Linseed Oil" },
    { standardCode: "200040000", standardName: "Karanja Oil" },
    { standardCode: "200050000", standardName: "Neem Oil" },
    { standardCode: "200060000", standardName: "Castor Oil" },
    { standardCode: "200070000", standardName: "Cottonseed Oil" },
    { standardCode: "200080000", standardName: "Mineral Oil" },
    { standardCode: "200090000", standardName: "Argemon Oil" },
    { standardCode: "200100000", standardName: "Turmeric Powder adulterated with lead Chromate" }
  ]



  const [selectedOption, setSelectedOption] = useState(1003);
  const [selectedStandardOption, setSelectedStandardOption] = useState(1);
  const [selectedObject,setSelectedObject] = useState({standardCode:100010000,standardName:'Barium Standard Solution'})
  const [uniqueValue, setUniqueValue] = useState("");

  // Function to handle dropdown change
  const handleDropdownChange = (e) => {
    console.log("some")
    try {
      const selectedItem = JSON.parse(e.target.value); // Parse the selected item from the value
      console.log("Parsed selected item: ", selectedItem);
      
      // Update state with the parsed object and standardCode
      setSelectedObject(selectedItem);
      setSelectedOption(parseInt(selectedItem.standardCode, 10)); // Ensure it's parsed as an integer
  
      console.log("Standard Code: ", selectedItem.standardCode); // Log the standardCode to check if it's valid
    } catch (error) {
      console.error("Error parsing selected item: ", error);
    } };

  const handleSelectOption = (e) => {
    const newValue = parseInt(e.target.value, 10); // Ensure selectedStandardOption is parsed as integer
    
    setSelectedStandardOption(newValue)

    newValue === 1 ? setSelectedOption(1003):newValue === 2? setSelectedOption(100010000):setSelectedOption(200010000)
  };

  useEffect(() => {
    if (selectedOption < 2000) {
      setUniqueValue("BND Number");
    } else {
      setUniqueValue("Reference Material");
    }
    
  }, [selectedOption]);
  
  const myValue = useSwitchCase(selectedOption);

  return (
    <div className="addStandard-main grid sm:grid-cols-2">
      <div className="bg-slate-800 flex p-2 flex-col gap-y-2 max-w-56">
        <h2 className="text-2xl border-b-2 text-center pb-2 text-white font-bold mt-1">
          ADD Certificate 
        </h2>
        <select
          onChange={handleSelectOption}
          className="border-2 rounded-sm"
          value={selectedStandardOption}
        >
          <option value={1}>BND Certificate </option>
          <option value={2}>AQCRM Certificate </option>
          <option value={3}>OIL Certificate </option>
        </select>
        {selectedStandardOption === 1 ? (
          <select
            className="border-2 rounded-sm"
            value={selectedOption}
            onChange={handleDropdownChange}
          >
            <option value={1003}>BND1003</option>
            <option value={1004}>BND1004</option>
            <option value={1005}>BND1005</option>
            <option value={1006}>BND1006</option>
            <option value={1007}>BND1007</option>
            <option value={1008}>BND1008</option>
            <option value={1009}>BND1009</option>
            <option value={1010}>BND1010</option>
            <option value={1011}>BND1011</option>
            <option value={1012}>BND1012</option>
            <option value={1013}>BND1013</option>
            <option value={1014}>BND1014</option>
            <option value={1015}>BND1015</option>
            <option value={1016}>BND1016</option>
            <option value={1017}>BND1017</option>
            <option value={1018}>BND1018</option>
            <option value={1019}>BND1019</option>
            <option value={1020}>BND1020</option>
            <option value={1021}>BND1021</option>
            <option value={1022}>BND1022</option>
            <option value={1023}>BND1023</option>
            <option value={1024}>BND1024</option>
            <option value={1025}>BND1025</option>
            <option value={1026}>BND1026</option>
            <option value={1027}>BND1027</option>
            <option value={1028}>BND1028</option>
            <option value={1029}>BND1029</option>
            <option value={1030}>BND1030</option>
            <option value={1031}>BND1031</option>
            <option value={1032}>BND1032</option>
            <option value={1033}>BND1033</option>
            <option value={1034}>BND1034</option>
            <option value={1035}>BND1035</option>
            <option value={1036}>BND1036</option>
            <option value={1037}>BND1037</option>
            <option value={1038}>BND1038</option>
            <option value={1039}>BND1039</option>
            <option value={1041}>BND1041</option>
            <option value={1042}>BND1042</option>
          </select>
        ) : selectedStandardOption === 2 ? (
          <select
            className="border-2 rounded-sm"
            value={selectedOption}
            onChange={handleDropdownChange}
          >
            <option>select</option>
           {aqcrm.map((item,index)=>(

            <option value={JSON.stringify(item)} >{item.standardName}</option>
           ))}
          </select>
        ) : (
          <select
            className="border-2 rounded-sm"
            value={selectedOption}
            onChange={handleDropdownChange}
            
            >
            <option>select</option>
            {oil.map((item,index)=>(
            <option value={JSON.stringify(item)} >{item.standardName}</option>
           ))}
          </select>
        )}
      </div>

      {myValue === 1 ? (
        <TypeOne
          url={"/createStandardType1"}
          Isupdate={false}
          selectedOption={selectedOption}
          selectedObject = {selectedObject}
          uniqueValue={uniqueValue}
        />
      ) : myValue === 2 ? (
        <TypeTwo
          url={"/createStandardType2"}
          Isupdate={false}
          selectedOption={selectedOption}
          selectedObject={selectedObject}
          uniqueValue={uniqueValue}
        />
      ) : (
        <TypeThree
          url={"/createStandardType3"}
          Isupdate={false}
          selectedOption={selectedOption}
          selectedObject={selectedObject}
          uniqueValue={uniqueValue}
        />
      )}
    </div>
  );
};

export default AddStandard;
