import { useEffect, useState } from "react";

export function useSwitchCase(value) {
  const [formType, setFormType] = useState(0);

  useEffect(() => {
    switch (value) {
      case 1003:
      case 1004:
      case 1009:
      case 1010:
      case 1011:
      case 1012:
      case 1013:
      case 1014:
      case 1015:
      case 1019:
      case 1020:
      case 1025:
      case 1026:
      case 1027:
      case 1028:
      case 1029:
      case 1030:
      case 1031:
      case 1032:
      case 1033:
      case 1034:
      case 1035:
      case 1036:
      case 1037:
      case 1038:
      case 100010000:
      case 100020000:
      case 100030000:
      case 100040000:
      case 100050000:
      case 100060000:
      case 100070000:
      case 100080000:
      case 100090000:
      case 100100000:
      case 100110000:
      case 100120000:
      case 100130000:
      case 100140000:
      case 100150000:
      case 100160000:
      case 100170000:
      case 100180000:
      case 100190000:
      case 100280000:
      case 100290000:
      
        setFormType(3);
        break;
      case 1005:
      case 1006:
      case 1007:
      case 1008:
      case 1016:
      case 1017:
      case 1018:
      case 1021:
      case 1022:
      case 1023:
      case 1024:
      case 1039:
      case 1041:
      case 1042:
      case 100400000:
      case 100410000:
      case 100210000:
      case 100200000:
      case 100220000:
      case 100230000:
      case 100440000:
      case 100370000:
      case 100390000:
      case 100380000:
      case 100300000:
        setFormType(1);
        break;
      case 200010000:
      case 200020000:
      case 200030000:
      case 200040000:
      case 200050000:
      case 200060000:
      case 200070000:
      case 200080000:
      case 200090000:
      case 200100000:
        setFormType(2);
        break;
      default:
        setFormType(10);
        break;
    }
  }, [value]);

  return formType;
}
