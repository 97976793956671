import axios from "../axios.js";
import React from 'react'
import { useForm } from 'react-hook-form'
import toast from "react-hot-toast";

const UpdateStandard = () => {
  const {register,handleSubmit, formState: { errors } } = useForm()
async function whileSubmit(data){
try {
  const responce = await axios.put('/updateUser',data)
  
  toast.success(responce.data.message)
} catch (error) {
  toast.error(error.response.data.message)
  console.log('Error in user update form')
}
 } 
  return (
    <div className='flex items-center justify-center bg-red-900 h-screen'>
      <form className='flex type-3Form flex-col justify-center w-96 items-center h-4/5' onSubmit={handleSubmit(whileSubmit)} >
        <h2 className='sm:text-2xl text-lg font-bold'>Refrence Material Details</h2>
      <input
            {...register("_Id", {
              required: "Bottle Number is required",
            })}
            className="login-input w-5/6 border-2  border-blue-400   rounded-sm mt-2 mb-2"
            type="text"
            placeholder='Bottle Number'
            id="certificateNumber"
          />
          {errors._Id && (
            <div className="text-red-500 font-semibold">
              {errors._Id.message}
            </div>
            )}
      <input
            {...register("standarCode", {
              required: "BND Number/Refrence Material is required",
            })}
            className="login-input w-5/6 border-2  border-blue-400   rounded-sm mt-2 mb-2"
            type="text"
            placeholder='BND Number/Refrence Material'
            id="certificateNumber"
          />
          {errors.standarCode && (
            <span className="text-red-500 text-sm font-semibold">
              {errors.standarCode.message}
            </span>
            )}
      <input
            {...register("lotNumber", {
              required: "Lot Number is required",
            })}
            className="login-input w-5/6 border-2  border-blue-400   rounded-sm mt-2 mb-2"
            type="text"
            placeholder='Lot Number'
            id="certificateNumber"
          />
          {errors.lotNumber && (
            <div className="text-red-500 font-semibold">
              {errors.lotNumber.message}
            </div>
            )}

        <h2 className=' sm:text-2xl text-lg font-bold'>Correct Company Details</h2>
      <input
            {...register("companyName", {
              required: "Bottle Number is required",
            })}
            className="login-input w-5/6 border-2  border-blue-400   rounded-sm mt-2 mb-2"
            type="text"
            placeholder='Bottle Number'
            id="certificateNumber"
          />
          {errors.companyName && (
            <div className="text-red-500 font-semibold">
              {errors.companyName.message}
            </div>
            )}
      <input
            {...register("email", {
              required: "BND Number/Refrence Material is required",
            })}
            className="login-input w-5/6 border-2  border-blue-400   rounded-sm mt-2 mb-2"
            type="text"
            placeholder='BND Number/Refrence Material'
            id="certificateNumber"
          />
          {errors.email && (
            <div className="text-red-500 text-sm font-semibold">
              {errors.email.message}
            </div>
            )}
      <input
            {...register("gstNumber", {
              required: "Lot Number is required",
            })}
            className="login-input w-5/6 border-2  border-blue-400   rounded-sm mt-2 mb-2"
            type="text"
            placeholder='GST Number'
            id="certificateNumber"
          />
          {errors.gstNumber && (
            <div className="text-red-500 font-semibold">
              {errors.gstNumber.message}
            </div>
            )}
            <button type="submit" className=' border-4 border-blue-400 p-2 pl-5 pr-5 rounded login-button  font-bold'>Submit</button>
      </form>
    </div>
  )
}

export default UpdateStandard
