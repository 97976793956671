import React from 'react'
import './welcome.css'
import { Link } from 'react-router-dom'
function Welcome() {
  return (
    <div className='welcome-main'>
      {/* <div className='marquee '>Aashvi Technology LLP, to produce most consistent Reference Materials at a revolutionary price, instant availability to build an encouraging and sustainable atmosphere to strengthen Quality Control activities of testing and R&D laboratories.</div> */}
     <img src="/Logo-1.png" alt="AQCRM" className='welcome-image' />
      <div className="wrapper">
        <svg>
            <text x="50%" y="50%" dy=".35em" textAnchor="middle">
                Aashvi Technology
            </text>
        </svg>
        <Link to={"standard/addStandards"}><button type="submit" className='btn-three1 relative left-1/2'  >Lets create some standards</button></Link>
    </div>
    </div>
  )
}

export default Welcome
