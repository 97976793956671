import axios from "../axios";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Navigate, useLocation } from "react-router-dom";
// import { Navigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setUser } from "../../../redux/user.slice";
import toast from "react-hot-toast";

export function TypeThree({ Isupdate, url, selectedOption,selectedObject, uniqueValue }) {
  const location = useLocation();
  const [expiry, setExpiry] = useState(false);
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm();
  const [Standardcode, setStandardCode] = useState(1003);
  const [emails, setemails] = useState([]);
  const [confirm, setconfirm] = useState(false);
  const [preview, setPreview] = useState(false);
  const [validation , setValidation] = useState([''])
  const [issue, setIssue] = useState({
    dd: 0o1,
    mm: 0o1,
    yyyy: 2000,
  });
  const [certificateExpiry, setCertificateExpiry] = useState({
    date: 0o1,
    month: "January",
    year: 2000,
  });

  function copyEmail() {
    navigator.clipboard.writeText(emails);
  }
  const handleCheckboxChange = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setValidation([...validation, value]);
    } else {
      setValidation(validation.filter((val) => val !== value));
    }
  };

  const whileSubmit3 = async (data) => {
    try {
      data.lotNumber = `AQCRM${data.lotNumber}`;
      data.standarCode = Standardcode;
      data.standardName = selectedObject.standardName
      data.certificateIssueDate = `${issue.dd}/${issue.mm}/${issue.yyyy}`;
      Standardcode < 2000
        ? console.log()
        : (data.documentVersion = `${Standardcode.toString()[0]}${
            Standardcode.toString()[3]
          }${Standardcode.toString()[4]}${issue.mm}${issue.dd}${issue.yyyy}`);
      data.expiry = `${certificateExpiry.month} ${certificateExpiry.date}, ${certificateExpiry.year}`;
      data.validation = validation
      data.standarCode += Number(data.volume);
     
      let response;
      if (Isupdate === true) {
        response = await axios.put(url, data);
        
      } else {
        response = await axios.post(url, data);

      }

      const date = new Date();
      date.toISOString();

      const sendData = {
        companyName: "Aashvi Techonology LLP Ahmedabad",
        email: "venktesh.pandey.abc@gmail.com",
        gstNumber: "48465184165161",
        fullStdData: data,
        createdAt: date,
      };
      dispatch(setUser(sendData));
      setPreview(true);

      if (response.data.status === false) {
        toast.error(response.data.message);
      } else {
        if (!response.data.users) {
          return Isupdate === true
            ? toast.error(response.data.message)
            : toast.success(response.data.message);
        }
        let arr = [];
        for (let i = 0; i < response.data.users.length; i++) {
          arr.push(response.data.users[i].email);
        }
        setemails(arr);

        toast.success(response.data.message);
        if (arr.length !== 0) {
          setconfirm(true);
        }
      }
    } catch (error) {
      console.error("Errorssss:", error.response.status);
      if (error.response.status === 401) {
        alert("Token is expired Kindly Login again");
        setExpiry(true);
      }
    }
  };

  useEffect(() => {
    setStandardCode(() => selectedOption);

    return () => {
      setStandardCode(1003);
    };
  }, [selectedOption]);

  return (
    <div className="flex justify-center items-center bndMainform">
      <form
        className="type-3Form grid sm:grid-cols-2 bg-slate-500 w-2/3 "
        onSubmit={handleSubmit(whileSubmit3)}
      >
      
        <div className="flex flex-col">
          <label className="text-xs" htmlFor="BND">{uniqueValue}:</label>
          <input
            {...register("standarCode")}
            className="login-input w-5/6 border-2  border-blue-400   rounded-sm mt-2 mb-2"
            value={Standardcode}
            disabled={true}
            onChange={(e) => (e.target.value = Standardcode)}
            type="text"
            id="BND"
          />

          <label className="text-xs" htmlFor="certificateNumber">Standard Name:</label>
          <input
            {...register("standardName", {
              required: "Standard Name is required",
            })}
            value={selectedObject.standardName}
            disabled={true}
            className="login-input w-5/6 border-2  border-blue-400   rounded-sm mt-2 mb-2"
            type="text"
            id="certificateNumber"
          />
          {errors.standardName && (
            <div className="text-red-500 font-semibold">
              {errors.standardName.message}
            </div>
          )}
          <label className="text-xs" htmlFor="lotNumber">Lot Number:</label>
          <div>
            <span>AQCRM-</span>{" "}
            <input
              {...register("lotNumber", {
                required: "Lot Number is required",
              })}
              className="login-input w-1/3 border-2  border-blue-400   rounded-sm mt-2 mb-2"
              type="number"
              id="lotNumber"
            />
          </div>
          {errors.lotNumber && (
            <div className="text-red-500 font-semibold">
              {errors.lotNumber.message}
            </div>
          )}
          <label className="text-xs" htmlFor="Volume">Volume:</label>
          <input
            {...register("volume", {
              required: "Volume required",
            })}
            className="login-input w-5/6 border-2  border-blue-400   rounded-sm mt-2 mb-2"
            type="text"
            id="Volume"
          />
          {errors.volume && (
            <div className="text-red-500 font-semibold">
              {errors.volume.message}
            </div>
          )}
          <label className="text-xs" htmlFor="certifiedValue1">Certified Value 1:</label>
          <input
            {...register("certifiedValue", {
              required: "Certified Value 1 required",
            })}
            className="login-input w-5/6 border-2  border-blue-400   rounded-sm mt-2 mb-2"
            type="text"
            id="certifiedValue1"
          />
          {errors.certifiedValue && (
            <div className="text-red-500 font-semibold">
              {errors.certifiedValue.message}
            </div>
          )}
          <label className="text-xs" htmlFor="certifiedValue2">Certified Value 2:</label>
          <input
            {...register("certifiedValue1", {
              required: "Certified Value 2 is required",
            })}
            className="login-input w-5/6 border-2  border-blue-400   rounded-sm mt-2 mb-2"
            type="text"
            id="certifiedValue2"
          />
          {errors.certifiedValue1 && (
            <div className="text-red-500 font-semibold">
              {errors.certifiedValue1.message}
            </div>
          )}
          <label className="text-xs" htmlFor="certificateIssueDate">Authorized Signatory:</label>
          <input
            {...register("authorizedSignatory", {
              required: "Document Version is required",
            })}
            className="login-input w-5/6 border-2  border-blue-400   rounded-sm mt-2 mb-2"
            type="text"
            id="certificateIssueDate"
          />
          {errors.authorizedSignatory && (
            <div className="text-red-500 font-semibold">
              {errors.authorizedSignatory.message}
            </div>
          )}
        </div>

        <div className="flex flex-col">
          <label className="text-xs" htmlFor="expandedUncertainty1">Expanded Uncertainty 1:</label>
          <input
            {...register("expandedUncertainty", {
              required: "Expanded Uncertainty 1 is required",
            })}
            className="login-input w-5/6 border-2  border-blue-400   rounded-sm mt-2 mb-2"
            type="text"
            id="expandedUncertainty1"
          />
          {errors.expandedUncertainty && (
            <div className="text-red-500 font-semibold">
              {errors.expandedUncertainty.message}
            </div>
          )}
          <label className="text-xs" htmlFor="expandedUncertainty2">Expanded Uncertainty 2:</label>
          <input
            {...register("expandedUncertainty1", {
              required: "Expanded Uncertainty 2 is required",
            })}
            className="login-input w-5/6 border-2  border-blue-400   rounded-sm mt-2 mb-2"
            type="text"
            id="expandedUncertainty2"
          />
          {errors.expandedUncertainty1 && (
            <div className="text-red-500 font-semibold">
              {errors.expandedUncertainty1.message}
            </div>
          )}
          <label className="text-xs" htmlFor="expiry">Expiry:</label>
          <div>
            <input
              type="text"
              onChange={(e) => {
                setCertificateExpiry({
                  ...certificateExpiry,
                  month: e.target.value,
                });
              }}
              className="login-input w-24 border-2  border-blue-400   rounded-sm mt-2 mb-2"
              placeholder="Month"
            />{" "}
            <input
              type="number"
              onChange={(e) => {
                setCertificateExpiry({
                  ...certificateExpiry,
                  date: e.target.value,
                });
              }}
              className="login-input w-11 border-2  border-blue-400   rounded-sm mt-2 mb-2"
              placeholder="DD"
            />
            ,{" "}
            <input
              type="number"
              onChange={(e) => {
                setCertificateExpiry({
                  ...certificateExpiry,
                  year: e.target.value,
                });
              }}
              className="login-input w-16 border-2  border-blue-400   rounded-sm mt-2 mb-2"
              placeholder="YYYY"
            />
          </div>
          {errors.expiry && (
            <div className="text-red-500 font-semibold">
              {errors.expiry.message}
            </div>
          )}
          <label className="text-xs" htmlFor="certificateIssueDate">Certificate Issue Date:</label>
          <div>
            {/* <input
            {...register("certificateIssueDate", {
              required: "Certificate Issue Date is required",
            })}
            className="login-input w-5/6 border-2  border-blue-400   rounded-sm mt-2 mb-2"
            type="text"
            id="certificateIssueDate"
          /> */}
            <input
              type="number"
              onChange={(e) => {
                setIssue({ ...issue, dd: e.target.value });
              }}
              className="login-input w-11 border-2  border-blue-400   rounded-sm mt-2 mb-2"
              placeholder="DD"
            />
            /
            <input
              type="number"
              onChange={(e) => {
                setIssue({ ...issue, mm: e.target.value });
              }}
              className="login-input w-14 border-2  border-blue-400   rounded-sm mt-2 mb-2"
              placeholder="MM"
            />
            /
            <input
              type="number"
              onChange={(e) => {
                setIssue({ ...issue, yyyy: e.target.value });
              }}
              className="login-input w-16 border-2  border-blue-400   rounded-sm mt-2 mb-2"
              placeholder="YYYY"
            />
          </div>
          {errors.certificateIssueDate && (
            <div className="text-red-500 font-semibold">
              {errors.certificateIssueDate.message}
            </div>
          )}
          {Standardcode < 2000 ? (
            <div className="flex flex-col w-full">
              {" "}
              <label className="text-xs" htmlFor="certificateIssueDate">Document Version:</label>
              <input
                {...register("documentVersion", {
                  required: "Document Version is required",
                })}
                className="login-input w-5/6 border-2  border-blue-400   rounded-sm mt-2 mb-2"
                type="text"
                id="certificateIssueDate"
              />
            </div>
          ) : (
            ""
          )}
          {errors.documentVersion && (
            <div className="text-red-500 font-semibold">
              {errors.documentVersion.message}
            </div>
          )}
          <label className="text-xs" htmlFor="certificateIssueDate">Quantity:</label>
          <input
            {...register("quantity", {
              required: Isupdate === true ? false : "Quantyty is required",
            })}
            className="login-input w-5/6 border-2  border-blue-400   rounded-sm mt-2 mb-2"
            type="number"
            disabled={Isupdate}
            id="quantity"
          />
          {errors.quantity && (
            <div className="text-red-500 font-semibold">
              {errors.quantity.message}
            </div>
          )}
          <label className="text-xs" htmlFor="certificateIssueDate">
            Issuing Authority\BND Head:
          </label>
          <input
            {...register("issuingAuthority", {
              required: "Document Version is required",
            })}
            className="login-input w-5/6 border-2  border-blue-400   rounded-sm mt-2 mb-2"
            type="text"
            id="certificateIssueDate"
          />
          {errors.issuingAuthority && (
            <div className="text-red-500 font-semibold">
              {errors.issuingAuthority.message}
            </div>
          )}
          <div className="flex justify-end w-5/6">
            <button
              className="  border-4 border-blue-400 p-2 pl-5 pr-5 rounded login-button  font-bold"
              type="submit"
              disabled={isSubmitting}
            >
              {isSubmitting ? <div className="loading"></div> : "Submit"}
            </button>
            <button
              className={`border-4 border-blue-400 ml-2 p-2 pl-5 pr-5 rounded login-button font-bold ${
                Isupdate === true && confirm === true ? "" : "sr-only"
              }`}
              type="button"
              onClick={copyEmail}
            >
              copy Email
            </button>
          </div>
        </div>
        <div id="style-7" className="flex flex-col w-96 checkbox-form">
          <div>
            <input
              type="checkbox"
              name="validation"
              id="AAS"
              onChange={handleCheckboxChange}
              value={" Atomic Absorption Spectrometer (AAS)"}
            />
            <label className="text-xs" htmlFor="AAS"> Atomic Absorption Spectrometer (AAS).</label>
          </div>
          <div>
            <input
              type="checkbox"
              name="validation"
              id="tm"
              onChange={handleCheckboxChange}
              value={
                " Titrimetry method"
              }
            />
              <label className="text-xs" htmlFor="tm">Titrimetry method.</label>
          </div>
          <div>
            <input
              type="checkbox"
              name="validation"
              id="fp"
              onChange={handleCheckboxChange}
              value={
                " Flame Photometer "
              }
            />
              <label className="text-xs" htmlFor="fp">Flame Photometer .</label>
          </div>
          <div>
            <input
              type="checkbox"
              name="validation"
              id="UV-VIS"
              onChange={handleCheckboxChange}
              value={" UV-VIS Spectroscopy"}
            />
            <label className="text-xs" htmlFor="UV-VIS">UV-VIS Spectroscopy.</label>
          </div>

          <div>
            <input
              type="checkbox"
              name="validation"
              id="ICP-OES"
              onChange={handleCheckboxChange}
              value={
                " Inductively Coupled Plasma Optical Emission Spectroscopy (ICP-OES)"
              }
            />
              <label className="text-xs" htmlFor="ICP-OES">Inductively Coupled Plasma Optical Emission Spectroscopy (ICP-OES).</label>
          </div>

          <div>
            <input
              type="checkbox"
              name="validation"
              id="ICP-MS"
              onChange={handleCheckboxChange}
              value={
                " Inductively Coupled Plasma Mass Spectroscopy (ICP-MS)"
              }
            />
              <label className="text-xs" htmlFor="ICP-MS">Inductively Coupled Plasma Mass Spectroscopy (ICP-MS).</label>
          </div>
          <div>
            <input
              type="checkbox"
              name="validation"
              id="IC-System"
              onChange={handleCheckboxChange}
              value={
                "Ion Selective Electrode method , Ion chromatography (IC System) and UV-VIS Spectrophotometry method."
              }
            />
              <label className="text-xs" htmlFor="IC-System">Ion Selective Electrode method , Ion chromatography (IC System) and UV-VIS Spectrophotometry method.</label>
          </div>
        </div>
      </form>
      {expiry === true ? (
        <Navigate state={{ from: location }} to="/ATLlogin" />
      ) : (
        console.log("")
      )}
      {preview === true ? (
        <Navigate
          state={{ from: location }}
          to="/ATLlabUserPannel/standard/preview"
        />
      ) : (
        ""
      )}
    </div>
  );
}

export function TypeOne({ url, Isupdate,selectedObject, selectedOption, uniqueValue }) {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm();
  const [Standardcode, setStandardCode] = useState(1003);
  const [emails, setemails] = useState([]);
  const [confirm, setconfirm] = useState(false);
  const [expiry, setExpiry] = useState(false);
  const [preview, setPreview] = useState(false);
  const [validation , setValidation] = useState([''])
  const [issue, setIssue] = useState({
    dd: 0o1,
    mm: 0o1,
    yyyy: 2000,
  });
  const [certificateExpiry, setCertificateExpiry] = useState({
    date: 0o1,
    month: "January",
    year: 2000,
  });

  const location = useLocation();
  function copyEmail() {
    navigator.clipboard.writeText(emails);
  }
  const dispatch = useDispatch();
  const handleCheckboxChange = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setValidation([...validation, value]);
    } else {
      setValidation(validation.filter((val) => val !== value));
    }
  };
  const whileSubmit1 = async (data) => {
    try {
      data.lotNumber = `AQCRM${data.lotNumber}`;
      data.standarCode = Standardcode;
      data.standardName = selectedObject.standardName
      Standardcode < 2000
        ? console.log()
        : (data.documentVersion = `${Standardcode.toString()[0]}${
            Standardcode.toString()[3]
          }${Standardcode.toString()[4]}${issue.mm}${issue.dd}${issue.yyyy}`);
      data.certificateIssueDate = `${issue.dd}/${issue.mm}/${issue.yyyy}`;
      data.expiry = `${certificateExpiry.month} ${certificateExpiry.date}, ${certificateExpiry.year}`;
      data.standarCode += Number(data.volume);
      data.validation = validation
      let response;
      if (Isupdate === true) {
        response = await axios.put(url, data);

      } else {
        response = await axios.post(url, data);

      }

      const date = new Date();
      date.toISOString();
      
      const sendData = {
        companyName: "Aashvi Techonology LLP Ahmedabad",
        email: "venktesh.pandey.abc@gmail.com",
        gstNumber: "48465184165161",
        fullStdData: data,
        createdAt: date,
      };
      dispatch(setUser(sendData));
      setPreview(true);
      

      if (response.data.status === false) {
        toast.error(response.data.message);
      } else {
        if (!response.data.users) {
          return Isupdate === true
            ? toast.error(response.data.message)
            : toast.success(response.data.message);
        }
        let arr = [];
        for (let i = 0; i < response.data.users.length; i++) {
          arr.push(response.data.users[i].email);
        }
        setemails(arr);
        
        toast.success(response.data.message);
        if (arr.length !== 0) {
          setconfirm(true);
        }
      }
    } catch (error) {
      console.error("Errorssss:", error.response.status);
      if (error.response.status === 401) {
        alert("Token is expired Kindly Login again");
        setExpiry(true);
      }
    }
  };
  useEffect(() => {
    setStandardCode(selectedOption);

    return () => {
      setStandardCode(1003);
    };
  }, [selectedOption]);

  return (
    <div className="flex justify-center items-center bndMainform">
      <form
        className="type-3Form grid sm:grid-cols-2 bg-slate-500 w-2/3 "
        onSubmit={handleSubmit(whileSubmit1)}
      >
        <div className="flex flex-col">
          <label className="text-xs" htmlFor="BND">{uniqueValue}:</label>
          <input
            {...register("standarCode")}
            className="login-input w-5/6 border-2  border-blue-400   rounded-sm mt-2 mb-2"
            value={Standardcode}
            disabled={true}
            onChange={(e) => (e.target.value = Standardcode)}
            type="text"
            id="BND"
          />

          <label className="text-xs" htmlFor="certificateNumber">Standard Name:</label>
          <input
            {...register("standardName")}
            value={selectedObject.standardName}
            disabled={true}
            className="login-input w-5/6 border-2  border-blue-400   rounded-sm mt-2 mb-2"
            type="text"
            id="certificateNumber"
          />
          {errors.standardName && (
            <div className="text-red-500 font-semibold">
              {errors.standardName.message}
            </div>
          )}
          <label className="text-xs" htmlFor="lotNumber">Lot Number:</label>
          <div>
            <span>AQCRM-</span>{" "}
            <input
              {...register("lotNumber", {
                required: "Lot Number is required",
              })}
              className="login-input w-1/3 border-2  border-blue-400   rounded-sm mt-2 mb-2"
              type="number"
              id="lotNumber"
            />
          </div>
          {errors.lotNumber && (
            <div className="text-red-500 font-semibold">
              {errors.lotNumber.message}
            </div>
          )}
          <label className="text-xs" htmlFor="Volume">Volume:</label>
          <input
            {...register("volume", {
              required: "Volume required",
            })}
            className="login-input w-5/6 border-2  border-blue-400   rounded-sm mt-2 mb-2"
            type="text"
            id="Volume"
          />
          {errors.volume && (
            <div className="text-red-500 font-semibold">
              {errors.volume.message}
            </div>
          )}
          <label className="text-xs" htmlFor="certifiedValue1">Certified Value 1:</label>
          <input
            {...register("certifiedValue", {
              required: "Certified Value 1 required",
            })}
            className="login-input w-5/6 border-2  border-blue-400   rounded-sm mt-2 mb-2"
            type="text"
            id="certifiedValue1"
          />
          {errors.certifiedValue && (
            <div className="text-red-500 font-semibold">
              {errors.certifiedValue.message}
            </div>
          )}
          <label className="text-xs" htmlFor="certifiedValue1">Authorized Signatory:</label>
          <input
            {...register("authorizedSignatory", {
              required: "Certified Value 1 required",
            })}
            className="login-input w-5/6 border-2  border-blue-400   rounded-sm mt-2 mb-2"
            type="text"
            id="certifiedValue1"
          />
          {errors.authorizedSignatory && (
            <div className="text-red-500 font-semibold">
              {errors.authorizedSignatory.message}
            </div>
          )}
        </div>

        <div className="flex flex-col w-full">
          <label className="text-xs" htmlFor="expandedUncertainty1">Expanded Uncertainty 1:</label>
          <input
            {...register("expandedUncertainty", {
              required: "Expanded Uncertainty 1 is required",
            })}
            className="login-input w-5/6 border-2  border-blue-400   rounded-sm mt-2 mb-2"
            type="text"
            id="expandedUncertainty1"
          />
          {errors.expandedUncertainty && (
            <div className="text-red-500 font-semibold">
              {errors.expandedUncertainty.message}
            </div>
          )}

          <label className="text-xs" htmlFor="expiry">Expiry:</label>
          <div>
            <input
              type="text"
              onChange={(e) => {
                setCertificateExpiry({
                  ...certificateExpiry,
                  month: e.target.value,
                });
              }}
              className="login-input w-24 border-2  border-blue-400   rounded-sm mt-2 mb-2"
              placeholder="Month"
            />{" "}
            <input
              type="number"
              onChange={(e) => {
                setCertificateExpiry({
                  ...certificateExpiry,
                  date: e.target.value,
                });
              }}
              className="login-input w-11 border-2  border-blue-400   rounded-sm mt-2 mb-2"
              placeholder="DD"
            />
            ,{" "}
            <input
              type="number"
              onChange={(e) => {
                setCertificateExpiry({
                  ...certificateExpiry,
                  year: e.target.value,
                });
              }}
              className="login-input w-16 border-2  border-blue-400   rounded-sm mt-2 mb-2"
              placeholder="YYYY"
            />
          </div>
          {errors.expiry && (
            <div className="text-red-500 font-semibold">
              {errors.expiry.message}
            </div>
          )}
          <label className="text-xs" htmlFor="certificateIssueDate">Certificate Issue Date:</label>
          <div>
            {/* <input
            {...register("certificateIssueDate", {
              required: "Certificate Issue Date is required",
            })}
            className="login-input w-5/6 border-2  border-blue-400   rounded-sm mt-2 mb-2"
            type="text"
            id="certificateIssueDate"
          /> */}
            <input
              type="text"
              onChange={(e) => {
                setIssue({ ...issue, dd: e.target.value });
              }}
              className="login-input w-11 border-2  border-blue-400   rounded-sm mt-2 mb-2"
              placeholder="DD"
            />
            /
            <input
              type="text"
              onChange={(e) => {
                setIssue({ ...issue, mm: e.target.value });
              }}
              className="login-input w-14 border-2  border-blue-400   rounded-sm mt-2 mb-2"
              placeholder="MM"
            />
            /
            <input
              type="text"
              onChange={(e) => {
                setIssue({ ...issue, yyyy: e.target.value });
              }}
              className="login-input w-16 border-2  border-blue-400   rounded-sm mt-2 mb-2"
              placeholder="YYYY"
            />
          </div>
          {errors.certificateIssueDate && (
            <div className="text-red-500 font-semibold">
              {errors.certificateIssueDate.message}
            </div>
          )}
          {Standardcode < 2000 ? (
            <div className="flex flex-col w-full">
              {" "}
              <label className="text-xs" htmlFor="certificateIssueDate">Document Version:</label>
              <input
                {...register("documentVersion", {
                  required: "Document Version is required",
                })}
                className="login-input w-5/6 border-2  border-blue-400   rounded-sm mt-2 mb-2"
                type="text"
                id="certificateIssueDate"
              />
            </div>
          ) : (
            ""
          )}
          {errors.documentVersion && (
            <div className="text-red-500 font-semibold">
              {errors.documentVersion.message}
            </div>
          )}
          <label className="text-xs" htmlFor="certificateIssueDate">Quantity:</label>
          <input
            {...register("quantity", {
              required: Isupdate === true ? false : "Quantyty is required",
            })}
            className="login-input w-5/6 border-2  border-blue-400   rounded-sm mt-2 mb-2"
            type="number"
            disabled={Isupdate}
            id="certificateIssueDate"
          />
          {errors.quantity && (
            <div className="text-red-500 font-semibold">
              {errors.quantity.message}
            </div>
          )}
          <label className="text-xs" htmlFor="certificateIssueDate">
            Issuing Authority\BND Head:
          </label>
          <input
            {...register("issuingAuthority", {
              required: "Document Version is required",
            })}
            className="login-input w-5/6 border-2  border-blue-400   rounded-sm mt-2 mb-2"
            type="text"
            id="certificateIssueDate"
          />
          {errors.issuingAuthority && (
            <div className="text-red-500 font-semibold">
              {errors.issuingAuthority.message}
            </div>
          )}
          

          <div className="flex justify-end w-5/6">
            <button
              className="  border-4 border-blue-400 p-2 pl-5 pr-5 rounded login-button  font-bold"
              type="submit"
              disabled={isSubmitting}
            >
              {isSubmitting ? <div className="loading"></div> : "Submit"}
            </button>

            <button
              className={`border-4 border-blue-400 ml-2 p-2 pl-5 pr-5 rounded login-button font-bold ${
                Isupdate === true && confirm === true ? "" : "sr-only"
              }`}
              type="button"
              onClick={copyEmail}
            >
              copy Email
            </button>
          </div>
        </div>
        <div id="style-7" className="flex flex-col checkbox-form">
        <div>
            <input
              type="checkbox"
              name="validation"
              id="IC-System"
              onChange={handleCheckboxChange}
              value={
                "Ion Selective Electrode method"
              }
            />
              <label className="text-xs" htmlFor="IC-System">Ion Selective Electrode method </label>
          </div>
          <div>
            <input
              type="checkbox"
              name="validation"
              id="IC-System"
              onChange={handleCheckboxChange}
              value={
                "Ion chromatography (IC System)"
              }
            />
              <label className="text-xs" htmlFor="IC-System"> Ion chromatography (IC System)</label>
          </div>
          <div>
            <input
              type="checkbox"
              name="validation"
              id="AAS"
              onChange={handleCheckboxChange}
              value={" Atomic Absorption Spectrometer (AAS)"}
            />
            <label className="text-xs" htmlFor="AAS"> Atomic Absorption Spectrometer (AAS).</label>
          </div>

          <div>
            <input
              type="checkbox"
              name="validation"
              id="UV-VIS"
              onChange={handleCheckboxChange}
              value={" UV-VIS Spectroscopy"}
            />
            <label className="text-xs" htmlFor="UV-VIS">UV-VIS Spectroscopy.</label>
          </div>

          <div>
            <input
              type="checkbox"
              name="validation"
              id="ICP-OES"
              onChange={handleCheckboxChange}
              value={
                " Inductively Coupled Plasma Optical Emission Spectroscopy (ICP-OES)"
              }
            />
              <label className="text-xs" htmlFor="ICP-OES">Inductively Coupled Plasma Optical Emission Spectroscopy (ICP-OES).</label>
          </div>

         
          <div>
            <input
              type="checkbox"
              name="validation"
              id="tm"
              onChange={handleCheckboxChange}
              value={
                " Titrimetry method"
              }
            />
              <label className="text-xs" htmlFor="tm">Titrimetry method.</label>
          </div>
          <div>
            <input
              type="checkbox"
              name="validation"
              id="fp"
              onChange={handleCheckboxChange}
              value={
                " Flame Photometer "
              }
            />
              <label className="text-xs" htmlFor="fp">Flame Photometer .</label>
          </div>
        </div>
      </form>
      {expiry === true ? (
        <Navigate state={{ from: location }} to="/ATLlogin" />
      ) : (
        console.log("")
      )}
      {preview === true ? (
        <Navigate
          state={{ from: location }}
          to="/ATLlabUserPannel/standard/preview"
        />
      ) : (
        ""
      )}
    </div>
  );
}

export function TypeTwo({ url,selectedObject, Isupdate, selectedOption, uniqueValue }) {
  const {
    register,
    handleSubmit,

    formState: { errors, isSubmitting },
  } = useForm();
  const [Standardcode, setStandardCode] = useState(1003);
  const [emails, setemails] = useState([]);
  const [confirm, setconfirm] = useState(false);
  const location = useLocation();
  const [expiry, setExpiry] = useState(false);
  const [preview, setPreview] = useState(false);
  
  const [issue, setIssue] = useState({
    dd: 0o1,
    mm: 0o1,
    yyyy: 2000,
  });
  const [certificateExpiry, setCertificateExpiry] = useState({
    date: 0o1,
    month: "January",
    year: 2000,
  });

  function copyEmail() {
    navigator.clipboard.writeText(emails);
  }
  const dispatch = useDispatch();
  const whileSubmit2 = async (data) => {
    try {
      data.lotNumber = `AQCRM${data.lotNumber}`;
      data.standarCode = Standardcode;
      data.standardName = selectedObject.standardName 
      data.certificateIssueDate = `${issue.dd}/${issue.mm}/${issue.yyyy}`;
      data.expiry = `${certificateExpiry.month} ${certificateExpiry.date}, ${certificateExpiry.year}`;
      Standardcode < 2000
        ? console.log()
        : (data.documentVersion = `${Standardcode.toString()[0]}${
            Standardcode.toString()[3]
          }${Standardcode.toString()[4]}${issue.mm}${issue.dd}${issue.yyyy}`);
      data.standarCode = Number(data.standarCode) + Number(data.volume);
      
      let response;
      if (Isupdate === true) {
        response = await axios.put(url, data);
       
      } else {
        response = await axios.post(url, data);
       
      }

      const date = new Date();
      date.toISOString();
    
      const sendData = {
        companyName: "Aashvi Techonology LLP Ahmedabad",
        email: "venktesh.pandey.abc@gmail.com",
        gstNumber: "48465184165161",
        fullStdData: data,
        createdAt: date,
      };
      dispatch(setUser(sendData));
      setPreview(true);
     

      if (response.data.status === false) {
        toast.error(response.data.message);
      } else {
        if (!response.data.users) {
          return Isupdate === true
            ? toast.error(response.data.message)
            : toast.success(response.data.message);
        }
        let arr = [];
        for (let i = 0; i < response.data.users.length; i++) {
          arr.push(response.data.users[i].email);
        }
        setemails(arr);
        
        toast.success(response.data.message);
        if (arr.length !== 0) {
          setconfirm(true);
        }
      }
    } catch (error) {
      console.error("Errorssss:", error.response.status);
      if (error.response.status === 401) {
        alert("Token is expired Kindly Login again");
        setExpiry(true);
      }
    }
  };

  

  useEffect(() => {
    setStandardCode(selectedOption);
    
  }, [selectedOption]);

  
  return (
    <div className="flex justify-center items-center bndMainform">
      <form
        className="type-3Form grid sm:grid-cols-2 bg-slate-500 w-2/3 "
        onSubmit={handleSubmit(whileSubmit2)}
      >
        <div className="flex flex-col">
          <label className="text-xs" htmlFor="BND">{uniqueValue}:</label>
          <input
            {...register("standarCode")}
            className="login-input w-5/6 border-2  border-blue-400   rounded-sm mt-2 mb-2"
            value={Standardcode}
            disabled={true}
            onChange={(e) => (e.target.value = Standardcode)}
            type="text"
            id="BND"
          />

          <label className="text-xs" htmlFor="certificateNumber">Standard Name:</label>
          <input
            {...register("standardName")}
            value={selectedObject.standardName}
            disabled={true}
            className="login-input w-5/6 border-2  border-blue-400   rounded-sm mt-2 mb-2"
            type="text"
            id="certificateNumber"
          />
          {errors.standardName && (
            <div className="text-red-500 font-semibold">
              {errors.standardName.message}
            </div>
          )}

          <label className="text-xs" htmlFor="lotNumber">Lot Number:</label>
          <div>
            <span>AQCRM-</span>{" "}
            <input
              {...register("lotNumber", {
                required: "Lot Number is required",
              })}
              className="login-input w-1/3 border-2  border-blue-400   rounded-sm mt-2 mb-2"
              type="number"
              id="lotNumber"
            />
          </div>
          {errors.lotNumber && (
            <div className="text-red-500 font-semibold">
              {errors.lotNumber.message}
            </div>
          )}
          <label className="text-xs" htmlFor="Volume">Volume:</label>
          <input
            {...register("volume", {
              required: "Volume required",
            })}
            className="login-input w-5/6 border-2  border-blue-400   rounded-sm mt-2 mb-2"
            type="number"
            id="Volume"
          />
          {errors.volume && (
            <div className="text-red-500 font-semibold">
              {errors.volume.message}
            </div>
          )}
          <label className="text-xs" htmlFor="certifiedValue1">Authorized Signatory:</label>
          <input
            {...register("authorizedSignatory", {
              required: "Certified Value 1 required",
            })}
            className="login-input w-5/6 border-2  border-blue-400   rounded-sm mt-2 mb-2"
            type="text"
            id="certifiedValue1"
          />
          {errors.authorizedSignatory && (
            <div className="text-red-500 font-semibold">
              {errors.authorizedSignatory.message}
            </div>
          )}
        </div>

        <div className="flex flex-col">
          <label className="text-xs" htmlFor="expiry">Expiry:</label>
          <div>
            <input
              type="text"
              onChange={(e) => {
                setCertificateExpiry({
                  ...certificateExpiry,
                  month: e.target.value,
                });
              }}
              className="login-input w-24 border-2  border-blue-400   rounded-sm mt-2 mb-2"
              placeholder="Month"
            />{" "}
            <input
              type="number"
              onChange={(e) => {
                setCertificateExpiry({
                  ...certificateExpiry,
                  date: e.target.value,
                });
              }}
              className="login-input w-11 border-2  border-blue-400   rounded-sm mt-2 mb-2"
              placeholder="DD"
            />
            ,{" "}
            <input
              type="number"
              onChange={(e) => {
                setCertificateExpiry({
                  ...certificateExpiry,
                  year: e.target.value,
                });
              }}
              className="login-input w-16 border-2  border-blue-400   rounded-sm mt-2 mb-2"
              placeholder="YYYY"
            />
          </div>
          {errors.expiry && (
            <div className="text-red-500 font-semibold">
              {errors.expiry.message}
            </div>
          )}
          <label className="text-xs" htmlFor="certificateIssueDate">Certificate Issue Date:</label>
          {/* <input
            {...register("certificateIssueDate", {
              required: "Certificate Issue Date is required",
            })}
            className="login-input w-5/6 border-2  border-blue-400   rounded-sm mt-2 mb-2"
            type="text"
            id="certificateIssueDate"
          /> */}
          <div>
            <input
              type="text"
              onChange={(e) => {
                setIssue({ ...issue, dd: e.target.value });
              }}
              className="login-input w-11 border-2  border-blue-400   rounded-sm mt-2 mb-2"
              placeholder="DD"
            />
            /
            <input
              type="text"
              onChange={(e) => {
                setIssue({ ...issue, mm: e.target.value });
              }}
              className="login-input w-14 border-2  border-blue-400   rounded-sm mt-2 mb-2"
              placeholder="MM"
            />
            /
            <input
              type="text"
              onChange={(e) => {
                setIssue({ ...issue, yyyy: e.target.value });
              }}
              className="login-input w-16 border-2  border-blue-400   rounded-sm mt-2 mb-2"
              placeholder="YYYY"
            />
          </div>
          {errors.certificateIssueDate && (
            <div className="text-red-500 font-semibold">
              {errors.certificateIssueDate.message}
            </div>
          )}
          {Standardcode < 2000 ? (
            <div className="flex flex-col w-full">
              {" "}
              <label className="text-xs" htmlFor="certificateIssueDate">Document Version:</label>
              <input
                {...register("documentVersion", {
                  required: "Document Version is required",
                })}
                className="login-input w-5/6 border-2  border-blue-400   rounded-sm mt-2 mb-2"
                type="text"
                id="certificateIssueDate"
              />
            </div>
          ) : (
            ""
          )}
          {errors.documentVersion && (
            <div className="text-red-500 font-semibold">
              {errors.documentVersion.message}
            </div>
          )}
          <label className="text-xs" htmlFor="certificateIssueDate">Quantity:</label>
          <input
            {...register("quantity", {
              required: Isupdate === true ? false : "Quantyty is required",
            })}
            className="login-input w-5/6 border-2  border-blue-400   rounded-sm mt-2 mb-2"
            type="text"
            disabled={Isupdate}
            id="certificateIssueDate"
          />
          {errors.quantity && (
            <div className="text-red-500 font-semibold">
              {errors.quantity.message}
            </div>
          )}
          <label className="text-xs" htmlFor="certificateIssueDate">
            Issuing Authority\BND Head:
          </label>
          <input
            {...register("issuingAuthority", {
              required: "Document Version is required",
            })}
            className="login-input w-5/6 border-2  border-blue-400   rounded-sm mt-2 mb-2"
            type="text"
            id="certificateIssueDate"
          />
          {errors.issuingAuthority && (
            <div className="text-red-500 font-semibold">
              {errors.issuingAuthority.message}
            </div>
          )}
          <div className="flex justify-end w-5/6">
            <button
              className="  border-4 border-blue-400 p-2 pl-5 pr-5 rounded login-button  font-bold"
              type="submit"
              disabled={isSubmitting}
            >
              {isSubmitting ? <div className="loading"></div> : "Submit"}
            </button>
            <button
              className={`border-4 border-blue-400 ml-2 p-2 pl-5 pr-5 rounded login-button font-bold ${
                Isupdate === true && confirm === true ? "" : "sr-only"
              }`}
              type="button"
              onClick={copyEmail}
            >
              copy Email
            </button>
          </div>
        </div>
        
      </form>
      {expiry === true ? (
        <Navigate state={{ from: location }} to="/ATLlogin" />
      ) : (
        console.log("")
      )}
      {preview === true ? (
        <Navigate
          state={{ from: location }}
          to="/ATLlabUserPannel/standard/preview"
        />
      ) : (
        ""
      )}
    </div>
  );
}
