import React, { useEffect, useState } from "react";
import {TypeOne,TypeTwo,TypeThree} from '../AddStandard/Forms.jsx'
import { useSwitchCase } from "../../../hooks/useSwitchCase.js";

const UpdateStandard = () => {
 

  const [selectedOption, setSelectedOption] = useState(1003);
  const [selectedStandardOption, setSelectedStandardOption] = useState(1);
  const [uniqueValue ,setUniqueValue] = useState('')
  // Function to handle dropdown change
  const handleDropdownChange = async (e) => {
    setSelectedOption(parseInt(e.target.value, 10)); // Ensure selectedOption is parsed as integer
    // console.log(selectedOption)
  };
  const handleSelectOption = (e) => {
    const newValue = parseInt(e.target.value, 10); // Ensure selectedStandardOption is parsed as integer
    
    setSelectedStandardOption(newValue)

    newValue === 1 ? setSelectedOption(1003):newValue === 2? setSelectedOption(100300500):setSelectedOption(200150001)
  };

  useEffect(()=>{
    if (selectedOption < 2000) {
      setUniqueValue('BND Number')
    } else{
      setUniqueValue('Refrence Material')
      
    }

  },[selectedOption])
  const myValue = useSwitchCase(selectedOption)
  
 

  return (
    <div className="addStandard-main grid sm:grid-cols-2 ">
      <div className="bg-slate-800 flex p-2 flex-col gap-y-2">
        <h2 className="text-2xl border-b-2 text-center pb-2 text-white font-bold mt-1">
          Update Certificate 
        </h2>
        <select
          onChange={handleSelectOption}
          className="border-2 rounded-sm"
          value={selectedStandardOption}
        >
          <option value={1}>BND Certificate </option>
          <option value={2}>AQCRM Certificate </option>
          <option value={3}>OIL Certificate </option>
        </select>
        {selectedStandardOption === 1 ? (
          <select
            className="border-2 rounded-sm"
            value={selectedOption}
            onChange={handleDropdownChange}
          >
            <option value={1003}>BND1003</option>
            <option value={1004}>BND1004</option>
            <option value={1005}>BND1005</option>
            <option value={1006}>BND1006</option>
            <option value={1007}>BND1007</option>
            <option value={1008}>BND1008</option>
            <option value={1009}>BND1009</option>
            <option value={1010}>BND1010</option>
            <option value={1011}>BND1011</option>
            <option value={1012}>BND1012</option>
            <option value={1013}>BND1013</option>
            <option value={1014}>BND1014</option>
            <option value={1015}>BND1015</option>
            <option value={1016}>BND1016</option>
            <option value={1017}>BND1017</option>
            <option value={1018}>BND1018</option>
            <option value={1019}>BND1019</option>
            <option value={1020}>BND1020</option>
            <option value={1021}>BND1021</option>
            <option value={1022}>BND1022</option>
            <option value={1023}>BND1023</option>
            <option value={1024}>BND1024</option>
            <option value={1025}>BND1025</option>
            <option value={1026}>BND1026</option>
            <option value={1027}>BND1027</option>
            <option value={1028}>BND1028</option>
            <option value={1029}>BND1029</option>
            <option value={1030}>BND1030</option>
            <option value={1031}>BND1031</option>
            <option value={1032}>BND1032</option>
            <option value={1033}>BND1033</option>
            <option value={1034}>BND1034</option>
            <option value={1035}>BND1035</option>
            <option value={1036}>BND1036</option>
            <option value={1037}>BND1037</option>
            <option value={1038}>BND1038</option>
            <option value={1039}>BND1039</option>
            <option value={1041}>BND1041</option>
            <option value={1042}>BND1042</option>
          </select>
        ) : selectedStandardOption === 2 ? (
          <select
            className="border-2 rounded-sm"
            value={selectedOption}
            onChange={handleDropdownChange}
          >
            <option value={100010000}>AQCRM 100010000</option>
            <option value={100020000}>AQCRM 100020000</option>
            <option value={100030000}>AQCRM 100030000</option>
            <option value={100040000}>AQCRM 100040000</option>
            <option value={100050000}>AQCRM 100050000</option>
            <option value={100060000}>AQCRM 100060000</option>
            <option value={100070000}>AQCRM 100070000</option>
            <option value={100080000}>AQCRM 100080000</option>
            <option value={100090000}>AQCRM 100090000</option>
            <option value={100100000}>AQCRM 100100000</option>
            <option value={100110000}>AQCRM 100110000</option>
            <option value={100120000}>AQCRM 100120000</option>
            <option value={100130000}>AQCRM 100130000</option>
            <option value={100140000}>AQCRM 100140000</option>
            <option value={100150000}>AQCRM 100150000</option>
            <option value={100160000}>AQCRM 100160000</option>
            <option value={100170000}>AQCRM 100170000</option>
            <option value={100180000}>AQCRM 100180000</option>
            <option value={100190000}>AQCRM 100190000</option>
            <option value={100200000}>AQCRM 100200000</option>
            <option value={100210000}>AQCRM 100210000</option>
            <option value={100220000}>AQCRM 100220000</option>
            <option value={100230000}>AQCRM 100230000</option>
            <option value={100240000}>AQCRM 100240000</option>
            <option value={100250000}>AQCRM 100250000</option>
            <option value={100260000}>AQCRM 100260000</option>
            <option value={100270000}>AQCRM 100270000</option>
            <option value={100280000}>AQCRM 100280000</option>
            <option value={100290000}>AQCRM 100290000</option>
            <option value={100300000}>AQCRM 100300000</option>
            <option value={100370000}>AQCRM 100370000</option>
            <option value={100380000}>AQCRM 100380000</option>
            <option value={100390000}>AQCRM 100390000</option>
            <option value={100440000}>AQCRM 100440000</option>
            <option value={100400000}>AQCRM Colour</option>
            <option value={100410000}>AQCRM Turbidity</option>
          </select>
        ) : (
          <select
            className="border-2 rounded-sm"
            value={selectedOption}
            onChange={handleDropdownChange}
          >
            <option value={200010000}>Groundnut Oil</option>
            <option value={200020000}>Sesame Oil</option>
            <option value={200030000}>Linseed Oil</option>
            <option value={200040000}>Karanja Oil</option>
            <option value={200050000}>Neem Oil</option>
            <option value={200060000}>Castor Oil</option>
            <option value={200070000}>Cottonseed Oil</option>
            <option value={200080000}>Mineral Oil</option>
            <option value={200090000}>Argemone Oil</option>
            <option value={200100000}>Turmeric powder with Lead Chromate</option>
          </select>
        )}
      </div>
      
      {myValue === 1? <TypeOne url={'/updateStandardType1'} Isupdate={true} selectedOption= {selectedOption} uniqueValue={uniqueValue}/> : myValue === 2? <TypeTwo url={'/updateStandardType2'} Isupdate={true} selectedOption= {selectedOption} uniqueValue={uniqueValue}/>: <TypeThree url={'/updateStandardType3'} Isupdate={true} selectedOption= {selectedOption} uniqueValue={uniqueValue}/>}
      
      
    </div>
  );
};



export default UpdateStandard
