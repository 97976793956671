import React, { useState } from 'react'
import { useForm } from 'react-hook-form';
import './deleteStandard.css'
import axios from "../axios.js";
import toast from 'react-hot-toast';
const DeleteStandard = () => {

const [message ,setMessage] =useState('')
const [Status ,setStatus] =useState(false)
  const {register,handleSubmit,formState: { errors }}= useForm()

  async function useingSubmit(data){
    try {
      const responce = await axios.delete('/deleteStandardType',{data})
      
      toast.success(responce.data.message)
      setMessage(responce.data.message)
      setStatus(responce.data.status)
      
    } catch (error) {
      console.dir(error)
      toast.error(error.response.data.message)
    }
  }

  return (
    <div className="flex justify-center items-center deleteMain w-full ">
    

      <form
        className="w-full grid sm:grid-cols-2 h-full  items-center justify-center "
        onSubmit={handleSubmit(useingSubmit)}
        >
          <img src='/garbage.png' className='h-3/5 p-1 max-md:h-1/4 max-sm:sr-only max-sm:h-1/4' alt='Some '></img>
          <div className='type-delete flex flex-col items-center  gap-y-4 max-sm:h-4/5  h-3/5 '>
          <h2 className='sm:text-2xl mt-3 font-bold'>Delete Standard</h2>
      

          
      <input
        {...register("_Id",{
          required:"Bottle Number is required"
        })}
        className="login-input w-4/6 border-2   border-blue-600   rounded-sm mt-3 mb-2"
        type="text"
        id="BND"
        placeholder='Bottle Number'
        />
{errors._Id && <div className='text-red-500 font-semibold'>{errors._Id.message}</div>}
      
      <input
        {...register("standarCode",{
          required:"Refrence Material/BND Number is required"
        })}
        className="login-input w-4/6 border-2   border-blue-600   rounded-sm  mb-2"
        type="text"
        id="certificateNumber"
        placeholder='Refrence Material/BND Number'
        />
       {errors.standarCode && <div className='text-red-500 font-semibold'>{errors.standarCode.message}</div>}
      
      <input
        {...register("lotNumber",{
          required:"Lot Number is required"
        })}
        className="login-input w-4/6 border-2   border-blue-600   rounded-sm  mb-2"
        type="text"
        placeholder='Lot Number'
        id="lotNumber"
        />
      {errors.lotNumber && <div className='text-red-500 font-semibold'>{errors.lotNumber.message}</div>}

                
        <button
          className="  border-4 border-blue-400 p-2 pl-5 pr-5 rounded login-button  font-bold"
          type="submit"
          >
          Submit
        </button>
         <div className={`text-xl ${Status===false?'text-red-500':'text-green-500'}`}  > {message} </div>
          </div>
        
          
        
      </form>
                </div>
    
  );
}

export default DeleteStandard
