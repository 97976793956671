import React, {  useState } from 'react'
import {useForm} from 'react-hook-form'
import './Login.css'
import axios from 'axios'
import { useAuth } from '../../../hooks/useAuth.js'
import { Navigate } from 'react-router-dom'


function Login() {
  const axiosWithCredentials = axios.create({
    baseURL: process.env.REACT_APP_BASE_URI, // Adjust the base URL as needed
    withCredentials: true, // Send cookies with the request
    headers: {
      'Content-Type': 'application/json'
    }
  });
  const [change,setChange ] = useState(true)
  const [status,setStatus ] = useState(false)
  const {register,handleSubmit,setError,formState:{errors,isSubmitting }} = useForm()
  const{ setUser} = useAuth()

 
  // const submitting =async function(data){

  //   try {
  //     const response = await axios.post('/login', data);
  //     
  //     if (response.data.status === true) {
  //       await localStorage.setItem('token', response.data.token);
  //
  //      await setUser(await response.data.user);
  //       // setError("root", { message: response.data.message });
  //
  //       setStatus(response.data.status)
  //     } else {
  //       setError("root", { message: response.data.message });
  //     }
  //   } catch (error) {
  //     // Handle network errors or other exceptions
  //     console.error('Error during login:', error);
  //     setError("root", { message: "An error occurred during login. Please try again later." });
  //   }
    
  // }
  const submitting = async function(data) {
    try {
      const response = await axiosWithCredentials.post('/login', data);
      if (response.data.status === true) {
        // Set token in localStorage
        // document.cookie = `token=${response.data.token}; path=/`;
        localStorage.setItem('token', response.data.token);
        setUser(response.data.user);
        setStatus(response.data.status);
      } else {
        setError("root", { message: response.data.message });
      }
    } catch (error) {
      console.error('Error during login:', error);
      setError("root", { message: "An error occurred during login. Please try again later." });
    }
  }
  let correctEmail= 'login-input'
  if(change === false){
    correctEmail = 'incorrect-login'
  }
  
  return (
    <div className=" login-main  h-full flex justify-center items-center">
      <div className=" login-form grid sm:grid-cols-2 md:w-5/6 md:h-3/5 bg-red-200 ">

        <img className='h-full p-3 border-2 border-white'  src="/login-form.jpg" alt="Hellow brother" />
        <form  className='flex flex-col items-center gap-2 sm:space-y-5' onSubmit={handleSubmit(submitting)}>
        <h2 className="text-xl font-bold mt-5">LOGIN</h2>
        <input
        {...register('email',{
          required:"Email is required",
          validate:(value)=> {
            if (!value.includes('@')) {
              setChange(false)
              return "Email must have @";
            }
            setChange(true)
          }
        })}
        
        
        className={`${correctEmail} w-5/6 border-2  border-blue-400  p-1 rounded-sm `}
        aria-autocomplete='none'
        type="text"
        placeholder="Email"
        />
        {errors.email && <div className='text-red-500 font-semibold'>{errors.email.message}</div>}
       
        <input {...register('password',{
          required:'Password is required',
          minLength:{
            value:8,
            message:"Password must contain minimum 8 charactor"
          }
        })} className={`${correctEmail} w-5/6 border-2  border-blue-400  p-1 rounded-sm `}  type="password" placeholder="Password" />
        {errors.password && <div className='text-red-500 font-semibold'>{errors.password.message}</div>}
        <button 
         className=" border-4 border-blue-400 p-2 pl-5 pr-5 rounded login-button font-bold" disabled={isSubmitting} type=" submit">
          {isSubmitting?<div className="loading"></div>:"Submit"}
        </button>
           {errors.root && <div className='text-red-500 font-semibold'>{errors.root.message}</div>}

{  status===true?    <Navigate to={'/ATLlabUserPannel'} />:console.log() }
        </form>
      </div>
    </div>
  );
}

export default Login;

// import React, {  useState } from 'react';
// import {useForm} from 'react-hook-form';
// import './Login.css';
// import axios from 'axios';
// import { useAuth } from '../../../context/authContextProvider.js';
// import { Navigate } from 'react-router-dom';

// function Login() {
//   const [change,setChange ] = useState(true);
//   const [status,setStatus ] = useState(false);
//   const {register,handleSubmit,setError,formState:{errors}} = useForm();
//   const{ user,setUser} = useAuth();

//   const submitting = async function(data) {
//     try {
//       const response = await axios.post('/login', data);
//       if (response.data.status === true) {
//         // Set the token in a cookie
//         document.cookie = `Token=${response.data.token}; path=/`;
//         setUser(response.data.user);
//         setStatus(response.data.status);
//       } else {
//         setError("root", { message: response.data.message });
//       }
//     } catch (error) {
//       console.error('Error during login:', error);
//       setError("root", { message: "An error occurred during login. Please try again later." });
//     }
//   }

//   let correctEmail= 'login-input';
//   if(change === false){
//     correctEmail = 'incorrect-login';
//   }

//   return (
//     <div className=" login-main  h-full flex justify-center items-center">
//       <div className=" login-form grid sm:grid-cols-2 md:w-5/6 md:h-3/5 bg-red-200 ">
//         <img className='h-full p-3 border-2 border-white'  src="/login-form.jpg" alt="Hellow brother" />
//         <form  className='flex flex-col items-center gap-2 sm:space-y-5' onSubmit={handleSubmit(submitting)}>
//           <h2 className="text-xl font-bold mt-5">LOGIN</h2>
//           <input
//             {...register('email',{
//               required:"Email is required",
//               validate:(value)=> {
//                 if (!value.includes('@')) {
//                   setChange(false);
//                   return "Email must have @";
//                 }
//                 setChange(true);
//               }
//             })}
//             className={`${correctEmail} w-5/6 border-2  border-blue-400  p-1 rounded-sm `}
//             aria-autocomplete='none'
//             type="text"
//             placeholder="Email"
//           />
//           {errors.email && <div className='text-red-500 font-semibold'>{errors.email.message}</div>}
//           <input {...register('password',{
//             required:'Password is required',
//             minLength:{
//               value:8,
//               message:"Password must contain minimum 8 charactor"
//             }
//           })} className={`${correctEmail} w-5/6 border-2  border-blue-400  p-1 rounded-sm `}  type="password" placeholder="Password" />
//           {errors.password && <div className='text-red-500 font-semibold'>{errors.password.message}</div>}
//           <button 
//             className=" border-4 border-blue-400 p-2 pl-5 pr-5 rounded login-button font-bold text- " type=" submit">
//             Submit
//           </button>
//           {errors.root && <div className='text-red-500 font-semibold'>{errors.root.message}</div>}
//           {status === true ? <Navigate to={'/ATLlabUserPannel'} /> : null}
//         </form>
//       </div>
//     </div>
//   );
// }

// export default Login;
