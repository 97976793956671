// import { createSlice } from "@reduxjs/toolkit";

// const initialState = {
//   stdData: { _id: "", stdName: "", lotNumber: "", expiry: "", eun: "" },
// };

// export const stdData = createSlice({
//   name: "stdData",
//   initialState,
//   reducers: {
//     set_id: (state, action) => {
//       state.stdData._id = action.payload;
//     },
//     setStdNumber: (state, action) => {
//       state.stdData.stdName = action.payload;
//     },
//     setLotNumber: (state, action) => {
//       state.stdData.lotNumber = action.payload;
//     },
//     setExpiry: (state, action) => {
//       state.stdData.expiry = action.payload;
//     },
//     setEnu: (state, action) => {
//       state.stdData.enu = action.payload;
//     },
//     resetStdData: () => initialState,
//   },
// });

// export const { set_id, setStdNumber, setLotNumber,setExpiry,setEnu, resetStdData } = stdData.actions;

// export default stdData.reducer;



// // dispatch(set_id(respData.productId._id));
// //       dispatch(setStdNumber(respData.productId.stdNumber));
// //       dispatch(setLotNumber(respData.productId.lotNumber));
// //       dispatch(setExpiry(respData.productId.expiry));
// //       dispatch(setEnu(respData.productId.enu));




// stdSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  _id: "",
  stdName: "",
  lotNumber: "",
  expiry: "",
  eun: ""
};

export const stdSlice = createSlice({
  name: "stdData",
  initialState,
  reducers: {
    set_id: (state, action) => {
      state._id = action.payload;
    },
    setStdName: (state, action) => {
      state.stdName = action.payload;
    },
    setLotNumber: (state, action) => {
      state.lotNumber = action.payload;
    },
    setExpiry: (state, action) => {
      state.expiry = action.payload;
    },
    setEun: (state, action) => {
      state.eun = action.payload;
    },
    resetStdData: () => initialState,
  },
});

export const { set_id, setStdName, setLotNumber, setExpiry, setEun, resetStdData } = stdSlice.actions;

export default stdSlice.reducer;
