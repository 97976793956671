
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  user: {
    fullStdData:{
      standarCode:404
    }
  }
}

export const userSlice = createSlice({
  name: 'user', // Change 'compData' to 'user'
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
    resetUser: () => initialState // Changed resetCompData to resetUser for consistency
  }
});

export const { setUser, resetUser } = userSlice.actions; // Changed resetCompData to resetUser for consistency

export default userSlice.reducer;