// import React, { useState } from 'react'
// import './script.css'
// import { useForm } from 'react-hook-form'
// import { useDispatch, useSelector } from 'react-redux'
// import { setCompanyName, setEmail, setGstNumber } from '../../redux/user.slice.js'; 
// import { Navigate } from 'react-router-dom';
// import axios from 'axios'
function Login() {
//   const dispatch = useDispatch();
//   const { register, handleSubmit } = useForm();
//   const [event , setEvent] = useState(false)
// const _Id = useSelector(state=>state.stdData._id )

//   const onSubmit = (data) => {
//     if (data.companyName === '' || data.email === '' || data.gstNumber === '') {
//       alert('Name, Email, and GST Number fields cannot be empty');
//     } else {
//       alert(`Your Company number is ${data.companyName}, Email is ${data.email}, and Your GST Number is ${data.gstNumber}`);
      
//       // Dispatch actions to update Redux state
//       dispatch(setCompanyName(data.companyName));
//       dispatch(setEmail(data.email));
//       dispatch(setGstNumber(data.gstNumber));
//       setEvent(true)
//       try {
       
//         axios.post('http://localhost:4000/v1/api/userData', {...data, _Id})
//       } catch (error) {
//         console.log("error")
//       }
//     }
//   };

  return (
    <div className='nav flex flex-col justify-center items-center h-screen bg-slate-500 '>
      {/* <form id='form' className='flex flex-col align-middle w-96 items-center h-96' onSubmit={handleSubmit(onSubmit)} >
        <h2 className='text-2xl text-white mt-2'>Company Details</h2>
        
        <input {...register("companyName")} type="text" className='input p-5 w-1/2 border-2 border-black m-1' placeholder='Company Name' />
        <input {...register("email")} type="email" className='input p-5 w-1/2 border-2 border-black m-1' placeholder='Email' />
        <input {...register("gstNumber")} type="number" className='input p-5 w-1/2 border-2 border-black m-1' placeholder='GST Number' />
        
        <button type="submit" className='btn-three' >Submit</button>
      </form>
      {event === true ?  <Navigate to="/download?" replareplace={true} />:console.log() 
} */}
    </div>
  )
}

export default Login
