import React from "react";
import "./table.css";
import axios from "../axios";
import toast from 'react-hot-toast'

import { useForm } from "react-hook-form";
function Table() {
  const { register, handleSubmit } = useForm();



  const axiosWithCredentials = axios.create({
    baseURL: process.env.REACT_APP_BASE_URI , // Adjust the base URL as needed
    withCredentials: true, // Send cookies with the request
    headers: {
      'Content-Type': 'application/json'
    }
  });



  async function whileSubmit(data) {
   try {
    const url = "/createTable"
    
     
     const responce= await axiosWithCredentials.post(url,data)
     toast.success(responce.data.message)
 
   } catch (error) {
    toast.error(error.response.data.message)
      console.log("Error in axios table")   
   }
  }

  return (
    <main className="create-table-main gap-x-4">
      <section className="table-Section1 flex flex-col justify-center h-full items-center ">
        <h2 className=" m-2 font-bold text-2xl">Create Table</h2>
        <p className="m-2"><strong className="text-red-500" >NOTE:</strong> Write the Values With there sign.</p>
        <form
          className="justify-center flex flex-col items-center"
          onSubmit={handleSubmit(whileSubmit)}
        >
            <input {...register("standarcode")} type="text" className="w-1/2  px-2 py-1 border mb-2 border-black" placeholder="BND/AQCRM Number" />
          <table className="w-full border-2 border-black">
            <thead>
              <tr className=" border-2 border-black">
                <th className="p-1">Temperature (°C)</th>
                <th className="p-1">Δ pH</th>
                <th className="p-1">Temperature (°C)</th>
                <th className="p-1">Δ pH</th>
              </tr>
            </thead>
            <tbody>
              <tr className="border-2 border-black">
                <td className="p-4">5</td>
                <td className="p-4">
                  <input
                    {...register("value11", {
                      required: "Value 11 is required",
                    })}
                    type="text"
                    className="w-full px-2 py-1 border border-black"
                    placeholder="Value 11"
                  />
                </td>
                <td className="p-4">30</td>
                <td className="p-4">
                  <input
                    {...register("value12", {
                      required: "Value 12 is required",
                    })}
                    type="text"
                    className="w-full px-2 py-1 border border-black"
                    placeholder="Value 12"
                  />
                </td>
              </tr>
              <tr className="border-2 border-black">
                <td className="p-4">20</td>
                <td className="p-4">
                  <input
                    {...register("value21", {
                      required: "Value 21 is required",
                    })}
                    type="text"
                    className="w-full px-2 py-1 border border-black"
                    placeholder="Value 21"
                  />
                </td>
                <td className="p-4">35</td>
                <td className="p-4">
                  <input
                    {...register("value22", {
                      required: "Value 22 is required",
                    })}
                    type="text"
                    className="w-full px-2 py-1 border border-black"
                    placeholder="Value 22"
                  />
                </td>
              </tr>
              <tr className="border-2 border-black">
                <td className="p-4">15</td>
                <td className="p-4">
                  <input
                    {...register("value31", {
                      required: "Value 31 is required",
                    })}
                    type="text"
                    className="w-full px-2 py-1 border border-black"
                    placeholder="Value 31"
                  />
                </td>
                <td className="p-4">40</td>
                <td className="p-4">
                  <input
                    {...register("value32", {
                      required: "Value 32 is required",
                    })}
                    type="text"
                    className="w-full px-2 py-1 border border-black"
                    placeholder="Value 32"
                  />
                </td>
              </tr>
              <tr className="border-2 border-black">
                <td className="p-4">20</td>
                <td className="p-4">
                  <input
                    {...register("value41", {
                      required: "Value 41 is required",
                    })}
                    type="text"
                    className="w-full px-2 py-1 border border-black"
                    placeholder="Value 41"
                  />
                </td>
                <td className="p-4">50</td>
                <td className="p-4">
                  <input
                    {...register("value42", {
                      required: "Value 42 is required",
                    })}
                    type="text"
                    className="w-full px-2 py-1 border border-black"
                    placeholder="Value 42"
                  />
                </td>
              </tr>
            </tbody>
          </table>

          <button className="btn-three1" type="submit">Submit</button>
        </form>
      </section>
     
    </main>
  );
}

export default Table;
