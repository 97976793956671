import axios from "../axios.js";
import React, {  useState } from 'react'
import {useForm} from 'react-hook-form'
import toast from "react-hot-toast";
const Register = () => {
  const [change,setChange ] = useState(true)
  const {register,handleSubmit,setError,formState:{errors}} = useForm()
const [status,setStatus]= useState(false)

 
  const submitting =async function(data){
   try {
     if (data.password !== data.confirmPassword) {
      return setError("root",{
         message:"Password And Conform Password should Match"
       })
     }
     const responce= await axios.post('/register',data)
     setStatus(responce.data.status)
     
       toast.success(responce.data.message)
     
  } catch (error) {
    console.log(error)
    
    toast.error(error.response.data.message)
   }
  }
  
  let correctEmail= 'login-input'
  if(change === false){
    correctEmail = 'incorrect-login'
  }
  
  return (
    <div className=" login-main  h-full flex justify-center items-center">
      <div className=" login-form grid sm:grid-cols-2 md:w-5/6 md:h-3/5 max-lg:h-full bg-red-200 ">

        <img className='h-5/6 p-3 border-2 border-white'  src="/login-form.jpg" alt="Hellow brother" />
        <form  className='flex flex-col h-full items-center gap-2 sm:space-y-5' onSubmit={handleSubmit(submitting)}>
        <h2 className="text-xl font-bold mt-5">Register</h2>
       
       <input {...register("userName",{
         required:"Full Name is required"
        })} type="text" className={`${correctEmail} w-5/6 border-2  border-blue-400  p-1 rounded-sm `}
        aria-autocomplete='none'
        placeholder="Name" />
       {errors.userName && <div className='text-red-500 font-semibold'>{errors.userName.message}</div>}
       
       <input
       {...register('email',{
         required:"Email is required",
         validate:(value)=> {
           if (!value.includes('@')) {
             setChange(false)
             return "Email must have @";
           }
           setChange(true)
         }
       })}
       className={`${correctEmail} w-5/6 border-2  border-blue-400  p-1 rounded-sm `}
       aria-autocomplete='none'
       type="text"
       placeholder="Email"
       />
       {errors.email && <div className='text-red-500 font-semibold'>{errors.email.message}</div>}
       
       <input {...register("phone",{
         required:"Phone number is required",
         maxLength:{
           value: 10,
           message:"Phone Number mus be a 10 digit number"
          },
          minLength:{
            value: 10,
            message:"Phone Number mus be a 10 digit number"
          }
        })} type="number" className={`${correctEmail} w-5/6 border-2  border-blue-400  p-1 rounded-sm `}
        aria-autocomplete='none'
        placeholder="Phone Number" />
       {errors.phone && <div className='text-red-500 font-semibold'>{errors.phone.message}</div>}
       
       
        <input {...register('userId',{
          required:'Password is required',
        })} className={`${correctEmail} w-5/6 border-2  border-blue-400  p-1 rounded-sm `}  type="text" placeholder="User Id" />
        {errors.userId && <div className='text-red-500 font-semibold'>{errors.userId.message}</div>}
       
        <input {...register('password',{
          required:'Password is required',
          minLength:{
            value:8,
            message:"Password must contain minimum 8 charactor"
          }
        })} className={`${correctEmail} w-5/6 border-2  border-blue-400  p-1 rounded-sm `}  type="password" placeholder="Password" />
        {errors.password && <div className='text-red-600 font-semibold'>{errors.password.message}</div>}
       
        <input {...register('confirmPassword',{
          required:'Password is required',
          minLength:{
            value:8,
            message:"Password must contain minimum 8 charactor"
          }
        })} className={`${correctEmail} w-5/6 border-2  border-blue-400  p-1 rounded-sm `}  type="password" placeholder="Confirm Password" />
        {errors.confirmPassword && <div className='text-red-500 font-semibold'>{errors.confirmPassword.message}</div>}
        <button 
         className=" border-4 border-blue-400 p-2 pl-5 pr-5 rounded login-button font-bold text- " type=" submit">
          Submit
        </button>
           {errors.root && <div className={`${status===true?"text-green-800":'text-red-500'} font-semibold`}>{errors.root.message}</div>}

        </form>
      </div>
    </div>
  );
}

export default Register;
