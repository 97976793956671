import React from 'react'
import './nav.css'
import { Link,Outlet } from 'react-router-dom'

function Nav() {
  return (
    <>
    <nav className='flex items-center  bg-slate-800'>
      <ul className='flex flex-row  text-white'>
        <li className='neonText m-2  font-bold p-2' ><Link to={''}>Certificate Download</Link></li>
        <li className='neonText m-2  font-bold p-2'><Link to={'application'}>Application</Link></li>
        <li className='neonText m-2  font-bold p-2'><Link to={'verification'}>Verification</Link></li>
      </ul>
    </nav>
     <Outlet/>
    </>
    
  )
}

export default Nav
