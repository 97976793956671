import axios from "../axios.js";
import React from 'react'
import { useForm } from 'react-hook-form'
import toast from "react-hot-toast";

const DeleteStandard = () => {
  
    const {register,handleSubmit, formState: { errors }  } = useForm()
  async function whileSubmit(data){
   
  try {
    const responce = await axios.delete('/deleteUser',{data})
    
    toast.success(responce.data.message)
  } catch (error) {
    console.log('Error in user update form')
    toast.error(error.response.data.message)
  }
   } 
  return (
    <div className='flex items-center justify-center bg-red-900 h-screen'>
      <form className='flex type-3Form flex-col justify-center w-96 items-center h-3/5' onSubmit={handleSubmit(whileSubmit)} >
      <h2 className=' sm:text-2xl text-lg font-bold'>Delete Company</h2>
      <input
            {...register("_Id", {
              required: "Bottle Number is required",
            })}
            className="login-input w-5/6 border-2  border-blue-400   rounded-sm mt-2 mb-2"
            type="text"
            placeholder='Bottle Number'
            id="certificateNumber"
          />
          {errors._Id && (
            <div className="text-red-500 font-semibold">
              {errors._Id.message}
            </div>
            )}
      <input
            {...register("standarCode", {
              required: "BND Number/Refrence Material is required",
            })}
            className="login-input w-5/6 border-2  border-blue-400   rounded-sm mt-2 mb-2"
            type="text"
            placeholder='BND Number/Refrence Material'
            id="certificateNumber"
          />
          {errors.standarCode && (
            <span className="text-red-500 text-sm font-semibold">
              {errors.standarCode.message}
            </span>
            )}
      <input
            {...register("lotNumber", {
              required: "Lot Number is required",
            })}
            className="login-input w-5/6 border-2  border-blue-400   rounded-sm mt-2 mb-2"
            type="text"
            placeholder='Lot Number'
            id="certificateNumber"
          />
          {errors.lotNumber && (
            <div className="text-red-500 font-semibold">
              {errors.lotNumber.message}
            </div>
            )}

        
            <button type="submit" className=' border-4 border-blue-400 p-2 pl-5 pr-5 rounded login-button  font-bold'>Submit</button>
      </form>
    </div>
  )
}

export default DeleteStandard
