import React, { useState } from "react";
import "./varification.css";
import axios from "../axios.public.js";
function Varification() {
  const [resData, setResData] = useState({ message: "Enter your Product ID" });

  async function handleSubmit(e) {
    e.preventDefault();
    try {
      const response = await axios.post(
        "/varification",
        { _Id }
      );
      setResData(response.data);
    } catch (error) {
      console.error("Error:", error);
    }
  }

  
  const [_Id, set_Id] = useState("");
  return (
    <div className="varification-container ">
      <div className="varification-div ">
        <form onSubmit={handleSubmit} className="varification-form ">
          <input
            type="text"
            placeholder="Product Id"
            className="w-4/5 p-1 rounded-sm m-4 border-solid border-2 border-gray-400 bg-slate-300 text-black"
            onChange={(e) => set_Id(e.target.value)}
          />
          <button type="submit" className="btn-three">
            Submit
          </button>
        </form>
      </div>
      <div className="varification-display">
        <div className="varification-data">
          {resData.product !== undefined ? (
            <div key={resData.product._id}>
              <h2
                id="companyName"
                className="text-black mt-4 pl-2 pt-2 items-start text-xl font-semibold "
              >
                {`Company Name:- ${resData.product.companyName}`}
              </h2>
              <p className="text-black mt-4 pl-2 pt-2 items-start">{`Product ID:- ${resData.product.stdData}`}</p>
              <p className="text-black mt-4 pl-2 pt-2 items-start">{`Email:- ${resData.product.email}`}</p>
              <p className="text-black mt-4 pl-2 pt-2 items-start">{`Gst Number:- ${resData.product.gstNumber}`}</p>
              <p className="text-black mt-4 pl-2 pt-2 items-start">{`Standard Name:- ${resData.product.fullStdData.standardName}`}</p>
              <p className="text-black mt-4 pl-2 pt-2 items-start">{`LOT Number:- ${resData.product.fullStdData.lotNumber}`}</p>
              <p className="text-black mt-4 pl-2 pt-2 items-start">{`LOT Number:- ${resData.product.createdAt.toLocaleString()}`}</p>
              <p className="text-black mt-4 pl-2 pt-2 items-start">
                Certificate Issued On:{" "}
                {resData.product.createdAt
                  ? new Date(
                      resData.product.createdAt
                    ).toLocaleString()
                  : ""}
              </p>
            </div>
          ) : (
            <h2
              id="companyName"
              className="text-red-500 mt-4 pl-2 pt-2 text-xl font-semibold items-start"
            >
              {resData.message}
            </h2>
          )}
        </div>
      </div>
    </div>
  );
}

export default Varification;
