import React, {  useRef } from "react";
import "./SideBar.css";
import { Link, Outlet } from "react-router-dom";
import axios from '../axios.js'
import { useAuth } from '../../../hooks/useAuth.js'
function SideBar() {
  const ToggleRef = useRef();
  const ToggleRef1 = useRef();
   const{ setUser} = useAuth()

const clickCheck = ()=>{
  if(!(ToggleRef1.current.style.display !== "none" && ToggleRef.current.style.display) !== "none"){
    ToggleRef1.current.style.display = "none" 
    ToggleRef.current.style.display = "none"
  }
}
  
  function onclick() {
    if (ToggleRef1.current && ToggleRef1.current.style.display !== "none") {
      ToggleRef1.current.style.display = "none";
    }
    if (ToggleRef.current) {
      ToggleRef.current.style.display =
        ToggleRef.current.style.display === "none" ? "block" : "none";
    }
  }

  function onclick1() {
    if (ToggleRef.current && ToggleRef.current.style.display !== "none") {
      ToggleRef.current.style.display = "none";
    }
    if (ToggleRef1.current) {
      ToggleRef1.current.style.display =
        ToggleRef1.current.style.display === "none" ? "block" : "none";
    }
  }

  async function logOutClick(){
    try {
    await  axios.post('/logout')

    const value= await localStorage.setItem('token','');
   
    setUser(value)
    } catch (error) {
      console.error(error,"Error in logOut function")
    }
  }

  return (
    <main className="main ">
      <aside   className="sideBar flex flex-col space-y-1 w-44 sticky " >
        <img src="./Logo-1.png" alt="aqcrm" className="h-18 opacity-85 border border-white rounded-md object-cover" />
        <h2 className="font-bold text-white text-center pb-2 mt-2 border-b-2 text-2xl">
          Controls
        </h2>
            
        <div  className="btn-group w-full dropend">
          <button
            type="button"
            onClick={() => onclick()}
            className="btn border-0 mt-2 font-bold w-full dropdown-toggle"
            
           
          >
            Certificate 
          </button>
          <ul
            ref={ToggleRef}
            style={{ display: "none" }}
            className="dropdown-menu z-10 w-full"
          >
            <div className="flex flex-col gap-y-4 p-2 text-justify">
              <Link 
             
              onClick={()=> clickCheck()}
                className="text-white border-b-2 pb-1 hover:opacity-50 border-gray-400"
                to={"standard/addStandards"}
              >
                Add Certificate 
              </Link>

              <Link 
              onClick={()=> clickCheck()}
                className="text-white border-b-2 pb-1 hover:opacity-50 border-gray-400"
                to={"standard/updateStandards"}
              >
                Update Certificate 
              </Link>
              <Link 
              onClick={()=> clickCheck()}
                className="text-white border-b-2 pb-1 hover:opacity-50 border-gray-400"
                to={"standard/getStandards"}
              >
                Download Certificate Data
              </Link>

              <Link 
              onClick={()=> clickCheck()}
                className="text-white border-b-2 pb-1 hover:opacity-50 border-gray-400"
                to={"standard/deleteStandards"}
              >
                Delete Certificate 
              </Link>
            </div>
          </ul>
        </div>
        <div className="btn-group w-full dropend">
          <button
            type="button"
            onClick={() => onclick1()}
            className="btn border-0 font-bold w-full dropdown-toggle"
          >
            Users
          </button>
          <ul
            ref={ToggleRef1}
            style={{ display: "none" }}
            className="dropdown-menu w-full"
          >
            <div className="flex flex-col gap-y-4 p-2 text-justify">
              <Link 
              onClick={()=> clickCheck()}
                className="text-white border-b-2 pb-1 hover:opacity-50 border-gray-400"
                to={"users/updateUsers"}
              >
                Update User
              </Link>

              <Link 
              onClick={()=> clickCheck()}
                className="text-white border-b-2 pb-1 hover:opacity-50 border-gray-400"
                to={"users/deleteUsers"}
              >
                Delete User
              </Link>
            </div>
          </ul>
        </div>

        <Link 
        onClick={()=> clickCheck()}
          to={"Register"}
          type="button"
          className="btn border-0 font-bold w-full  dropdown-toggle"
        >
          Register
        </Link>
        <Link 
        onClick={()=> clickCheck()}
          to={"Tables"}
          type="button"
          className="btn border-0 font-bold w-full dropdown-toggle"
        >
          Tables
        </Link>
        <Link 
        onClick={()=> clickCheck()}
          to={"createTables"}
          type="button"
          className="btn border-0 font-bold w-full dropdown-toggle"
        >
         Create Tables
        </Link>
        
          <button onClick={logOutClick} className="btn-logout border-0 bg-red-600 font-bold w-full dropdown-toggle">Log Out</button>
        
      </aside>
      <section className="section">
        {" "}
        <Outlet />
      </section>
    </main>
  );
}

export default SideBar;
