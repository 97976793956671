import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';

import reportWebVitals from './reportWebVitals';
import {Toaster} from 'react-hot-toast'
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";
// all components
import Form from './components/form/Form';
import Nav from './components/Nav/Nav';
import SendFile from './components/sendFile/SendFile';
import Trial from './components/iframe/Trial';
import Application from './components/application/ApplicationForm.jsx';
import { Provider } from 'react-redux';
import { Store } from './redux/store.js';
import Varification from './components/Varification/Varification.jsx';

import SideBar from './components/ATLcomponents/SideBar/SideBar.jsx';
import AddStandard from './components/ATLcomponents/AddStandard/AddStandard.jsx'
import UpdateStandard from './components/ATLcomponents/updateStandard/UpdateStandard.jsx';
import DeleteStandard from './components/ATLcomponents/DeleteStandard/DeleteStandard.jsx';
import Register from './components/ATLcomponents/Register/Register.jsx';

import UpdateUser from './components/ATLcomponents/UpdateUser/UpdateUser.jsx'
import DeleteUser from './components/ATLcomponents/DeleteUser/DeleteUser.jsx'
import Login from './components//ATLcomponents/Login/Login.jsx';
import GetStandards from './components/ATLcomponents/getStandards/GetStandards.jsx';
import { AuthContextProvider } from './context/authContextProvider.js';
import PrivateRoute from './components/PrivateRoutes.jsx';
import Welcome from './components/ATLcomponents/Welcome/Welcome.jsx';
import Table from './components/ATLcomponents/Table/Table.jsx';
import CreateTable from './components/ATLcomponents/Table/CreateTable.jsx';




      const router = createBrowserRouter(
        createRoutesFromElements(
          <>
    {/* Unprocted Routes */}
    <Route path='/' element={<><Nav/> </>}>
      <Route path='' element={<><Form/></>} />
      <Route path='certificate' element={<SendFile/>} />
      <Route path='download' element={<Trial preview={ false}/>} />
      <Route path='application' element={<Application/>} />
      <Route path='varification' element={<Varification/>} />
    </Route>
      <Route path='/ATLlogin' element={<Login />} />
          {/* Procted Routes */}
    <Route path='/ATLlabUserPannel' element={<PrivateRoute/>}>
    <Route path=''  element={<SideBar></SideBar>}>
      <Route path='' element={<Welcome/>} />
      <Route path='Tables' element={<><Table/></>} />
      <Route path='createTables' element={<><CreateTable/></>} />
      <Route path='standard/addStandards' element={<AddStandard/>} />
      <Route path='standard/preview' element={<Trial preview={ true} />} />
      <Route path='standard/updateStandards' element={<UpdateStandard/>} />
      <Route path='standard/getStandards' element={<GetStandards/>} />
      <Route path='standard/deleteStandards' element={<DeleteStandard/>} />
      <Route path='users/updateUsers' element={<UpdateUser/>} />
      <Route path='users/deleteUsers' element={<DeleteUser/>} />
      <Route path='register' element={<Register/>} /> 
      <Route path='application' element={<Application/>} />
    </Route>
    </Route>

    </>

));

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={Store}>
      <AuthContextProvider>
        <Toaster />
        <RouterProvider router={router} />
      </AuthContextProvider>
    </Provider>
  </React.StrictMode>
    
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
